import Cookies from 'js-cookie';

const TokenKey = 'token';
const LoginSourceKey = 'login_source';

const getToken = () => Cookies.get(TokenKey);

const setToken = (token) => Cookies.set(TokenKey, token);

const removeToken = () => Cookies.remove(TokenKey);

const setLoginSource = (siteName) => Cookies.set(`${LoginSourceKey}-${siteName}`, window.location.host, {
    secure: true,
    domain: '.zaviago.com',
    sameSite: 'lax'
});

export { getToken, removeToken, setToken, setLoginSource };

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}