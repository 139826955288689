import { AccountModalMenus } from "src/components/topbar/components/AccountModalMenus";
import { ACCOUNT_MENUS } from "src/components/topbar/components/menus";
import { useUser } from "./useUser";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableRow } from "src/components/ui/table";
import { useEffect, useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/avatar"
import { Check } from "@untitled-ui/icons-react/build/cjs";
import { useLocation, useNavigate } from "react-router-dom";

const useTopbarMenu = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { user, logout } = useUser();
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const filterMenu = (group) => {
    return ACCOUNT_MENUS.filter(menu => menu.path.includes(group))
  }

  const checkIfHasForms = location.pathname === "/my-account" || location.pathname === "/billing/billing-address"

  const DefaultComp = () => (
    <section className="flex flex-col gap-10">
      <div className="bg-lightgray-100 rounded-lg px-4 py-3 flex items-center gap-3 cursor-pointer" onClick={() => navigate('/my-account')}>
        <Avatar className='w-13 h-13'>
          <AvatarImage src="" />
          <AvatarFallback>{user?.first_name[0]}</AvatarFallback>
        </Avatar>
        <div className="leading-5">
          <h3 className="text-black text-base">{`${user?.first_name} ${user?.last_name}` || "Name Surname"}</h3>
          <p className="text-lightgray-900/60 text-sm">{user?.email || 'test@mail.com'}</p>
        </div>
      </div>
      <AccountModalMenus title="BILLING & PLAN" menus={filterMenu("billing")} onSelect={(val) => navigate(val.path)} className="pl-4" />
      <AccountModalMenus title="TEAMS MEMBER" menus={filterMenu("teams")} onSelect={(val) => navigate(val.path)} className="pl-4" />
      <AccountModalMenus title="LANGUAGE" className="pl-4">
        <Table className="relative left-10">
          <TableBody>
            <LanguageChoice language="th" type="Thai" />
            <LanguageChoice language="en" type="English" />
          </TableBody>
        </Table>
      </AccountModalMenus>
      <AccountModalMenus menus={filterMenu("logout")} className="pl-4" onSelect={() => logout()} />
    </section>
  )

  useEffect(() => {
    if (localStorage.lang === undefined || null) {
      localStorage.setItem('lang', i18n.language)
    }
  }, [localStorage.lang])

  const LanguageChoice = ({ language, type }) => {
    const handleChangeLang = (lang) => {
      if (localStorage.lang !== null) {
        i18n.changeLanguage(lang)
        localStorage.setItem('lang', lang)
      }
    }
    return (
      <TableRow className="cursor-pointer">
        <TableCell className="px-0 py-3 -ml-10 flex items-center w-full font-normal text-black text-base gap-4" onClick={() => handleChangeLang(language)}>
          {localStorage.lang === language ? (
            <span className="flex w-6 h-6 bg-link rounded-full items-center justify-center">
              <Check color="white" className="w-4 h-4" viewBox="0 0 24 24" />
            </span>
          ) : (
            <span className="flex w-6 h-6 rounded-full border-2 border-lightgray-950"></span>
          )}
          <p>{type}</p>
        </TableCell>
      </TableRow>
    )
  }
  const defaultMenu = {
    title: "Account",
    element: <DefaultComp />,
    path: "/account",
  }

  const handleBack = () => {
    navigate(-1)
  };

  return { defaultMenu, isModalOpen, setIsModalOpen, handleBack, checkIfHasForms }
}

export default useTopbarMenu