import { useParams } from "react-router-dom"
import { useState, useRef, useEffect } from "react";
import { Input } from "src/components/ui/input";
import { Button } from "src/components/ui/button";
import { useToast } from "src/components/ui/use-toast";
import { useMutation, useQuery } from "react-query";
import { site, team } from "../../client/api";
import { useUser } from "../../hooks/useUser";
import { Skeleton } from "src/components/ui/skeleton";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { AccountModalMenus } from "src/components/topbar/components/AccountModalMenus";
import { Table, TableBody, TableCell, TableRow } from "src/components/ui/table";
import { Icons } from "src/components/ui/icons";
import { inviteTeamImages } from "src/components/icon-menus/workspace-images";
import { usePostHog } from "posthog-js/react";

export default function Teams() {
  const { t } = useTranslation()
  const { user, auth, getUser } = useUser();
  const { id } = useParams()
  const posthog = usePostHog();
  const [open, setOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)
  const inviteLinkRef = useRef(null)

  console.log("id", id);

  const copyLinkApps = [
    { title: 'AirDrop', icon: inviteTeamImages.airdrop },
    { title: 'Line', icon: inviteTeamImages.lineMessage },
    { title: 'Mail', icon: inviteTeamImages.mail },
    { title: 'QR Scan', icon: inviteTeamImages.qrscan },
    { title: 'Reminders', icon: inviteTeamImages.reminders }
  ]

  const { data: sites } = useQuery('sites', site.list, {
    enabled: !!user,
  });

  const { data: siteOverview } = useQuery(['site', `${sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name}`], () => site.overview(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), {
    enabled: !!sites?.site_list.length
  });

  const { mutate: loginAsAdmin } = useMutation('loginAsAdmin', ({ name, reason }) => site.login(name, reason), {
    onSuccess: (res) => {
      const { sid, site } = res.data.message;
      if (sid && site) {
        window.open(`https://${site}/app/home?sid=${sid}&posthog_session_id=${posthog.get_session_id()}`, '_blank');
      }
    }
  });

  const copyLink = () => {
    inviteLinkRef.current.select()
    document.execCommand('copy')
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 3000)
  }

  return (
    <section className="flex flex-col gap-10 items-center w-full">
      <Icons.teamPageIcon />
      <section className="w-full">
        {auth?.team?.invite_code ? (
          <AccountModalMenus className="bg-white relative" title="SHARE LINK">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center px-3 border-r border-lightgray-700 tracking-sfpro text-sm">
              https://
            </div>
            <Input
              style={{ paddingLeft: "84px" }}
              className="bg-white border border-lightgray-700 text-sm text-link tracking-sfpro h-11"
              value={`${window.location.host}/invite/${auth.team.invite_code}`}
              ref={inviteLinkRef}
            />
          </AccountModalMenus>
        ) : <Skeleton className='h-9 w-full mt-6 mb-[10px]' />}
      </section>

      {auth?.team && (
        <section className="w-full">
          <SettingsDialogContent
            setOpen={setOpen}
            open={open}
            initialValues={{
              invite_code_rotation_interval: auth.team.invite_code_rotation_interval,
              invite_code_usage_limit: auth.team.invite_code_usage_limit
            }} />
        </section>
      )}

      <section className="flex flex-col gap-2 items-center w-full">
        <div className="w-full">
          <AccountModalMenus className="p-6 rounded-[23px]">
            <div className="flex justify-center gap-[26px] w-full">
              {copyLinkApps.map(app => (
                <button className="flex flex-col gap-[7px] items-center">
                  <img src={app.icon} />
                  <p className="text-[11px] tracking-sfpro">{app.title}</p>
                </button>
              ))}
            </div>
          </AccountModalMenus>
        </div>
        <p className="text-xs text-[#858585] mb-1">{t('or')}</p>
        <Button onClick={copyLink} disabled={isCopied} className="regis-btn">{t(isCopied ? 'Copied' : 'Copy Link')}</Button>
      </section>
    </section>
  )
}

export const SettingsDialogContent = ({ ...props }) => {
  const { getUser } = useUser()
  const { toast } = useToast()

  const { t } = useTranslation()

  const expireAfter = ['12 Hours', '1 Day', '3 Days', '7 Days']
  const maximumUses = ['1 use', '5 uses', '10 uses', '20 uses', '30 uses', 'No Limit']

  const formik = useFormik({
    initialValues: props.initialValues,
    onSubmit: (values) => team.change_team_settings(values).then(() => {
      getUser()
      generateLink()
    })
  });

  useEffect(() => {
    formik.handleSubmit();
  }, [formik.values])


  const generateLink = () => {
    props.setOpen(false)
    // toast({
    //   title: (<div className="flex gap-x-3">
    //     <CheckCircle2 className="h-4 w-4 mt-1" />
    //     <div className="flex flex-col gap-y-1">
    //       <h3>{t('teams.link_generated.title')}</h3>
    //       <p className="font-normal">{t('teams.link_generated.desc')}</p>
    //     </div>
    //   </div>),
    // })
  }
  return (
    <AccountModalMenus title="EDIT INVITE LINK" className="pl-4">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="flex justify-between items-center mr-4 px-0">
              <label className="text-base">
                {t('teams.expire_after')}
              </label>
              <select
                className='bg-[#7878801F] text-base appearance-none px-[11px] py-1.5 rounded-md text-link text-center !outline-none'
                name="invite_code_rotation_interval"
                value={formik.values.invite_code_rotation_interval}
                onChange={formik.handleChange}
              >
                {expireAfter.map(r =>
                  <option value={r} key={r}>{t(`teams.${r}`)}</option>
                )}
              </select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="flex justify-between items-center mr-4 px-0">
              <label className="text-base">
                {t('teams.maximum_uses')}
              </label>
              <select
                className='bg-[#7878801F] text-base appearance-none px-[11px] py-1.5 rounded-md text-link text-center !outline-none'
                name="invite_code_usage_limit"
                value={formik.values.invite_code_usage_limit}
                onChange={formik.handleChange}
              >
                {maximumUses.map(m =>
                  <option value={m} key={m}>{t(`teams.${m}`)}</option>
                )}
              </select>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </AccountModalMenus>
    // <DialogContent className="w-[90%] md:w-full">
    //   <DialogHeader>
    //     <DialogTitle>{t('teams.edit_invitation')}</DialogTitle>
    //     <DialogDescription className='flex flex-col gap-y-6 py-6'>


    //       {/* <div className="anim-up flex flex-col"> 
    //       {/* <label className="subheading mb-2 font-medium">
    //         Expire after
    //       </label>
    //       <Select className='form-input'
    //         name="invite_code_rotation_interval"
    //         defaultValue={expireAfter[0]}
    //         value={formik.values.invite_code_rotation_interval}
    //         onchange={opt => formik.setFieldValue('invite_code_rotation_interval', opt)}
    //       >
    //         <SelectTrigger className="w-full">
    //           <SelectValue defaultValue={expireAfter[0]} />
    //         </SelectTrigger>
    //         <SelectContent>
    //           {expireAfter.map(r =>
    //             <SelectItem value={r}>{r}</SelectItem>
    //           )}
    //         </SelectContent>
    //       </Select>
    //     </div>

    //     <div className="anim-up flex flex-col">
    //       <label className="subheading mb-2 font-medium">
    //         Maximum number of uses
    //       </label>
    //       <Select
    //         className='form-input'
    //         name="invite_code_usage_limit"
    //         value={formik.values.invite_code_usage_limit}
    //         onchange={opt => console.log(opt)}
    //       >
    //         <SelectTrigger className="w-full">
    //           <SelectValue defaultValue={maximumUses[0]} />
    //         </SelectTrigger>
    //         <SelectContent>
    //           {maximumUses.map(m =>
    //             <SelectItem value={m}>{m}</SelectItem>
    //           )}
    //         </SelectContent>
    //       </Select>
    //     </div> */}
    //     </DialogDescription>
    //   </DialogHeader>
    //   <DialogFooter>
    //     <div className="flex w-full justify-between">
    //       <DialogClose asChild>
    //         <Button type="button" variant="ghost">
    //           {t('cancel')}
    //         </Button>
    //       </DialogClose>
    //       <Button type="button" onClick={formik.handleSubmit}>
    //         {t('teams.generate_link')}
    //       </Button>
    //     </div>
    //   </DialogFooter>
    // </DialogContent>
  )
}