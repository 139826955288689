import { Archive, AtSign, Bookmark, ClipboardPlus, CloudBlank01, CoinsSwap01, File06, FileCheck01, FileSearch01, FileSearch02, MessageCircle02, MessageDotsCircle, Percent03, PresentationChart02, Star01, Users03 } from "@untitled-ui/icons-react/build/cjs";
import { BadgeAlert, Bell, BellDot, BookUser, Building, Calendar, CalendarPlus, ChartNoAxesColumnIncreasing, CircleDollarSign, CirclePlus, CloudUpload, FilePen, Files, FolderOpen, FolderPen, GitCompareArrows, GitPullRequestCreateArrow, Grid2x2Check, ListChecks, ListTree, MessagesSquare, NotepadText, Share2, SquareDashedBottom, SquarePen, UserCog } from "lucide-react";
import { useState } from "react";
import { workspaceImages } from "src/components/icon-menus/workspace-images";
import { textGradient } from "src/lib/utils";
import { getToken } from "src/utils/helper";

const useDashboardActivities = () => {

  const [submenuIndex, setSubmenuIndex] = useState(0)
  const [titleModal, setTitleModal] = useState({
    title: "", titleColor:"", key:"", icon:"", desc:"", descColor:"", sidebarMenus: []
  })

  const { sidebarMenus } = titleModal
  const submenusList = sidebarMenus[submenuIndex]
  const sso_token = getToken();

  const ssoClick = () => {
    const team = localStorage.getItem('current_team');
    let url = `https://app.zaviago.com/app-sso?token=${sso_token}`;

    if (team !== null && team !== undefined) {
      url += `&team=${team}`;
    }
    window.location.href = url;
  }

  const dashboardTriggers = [
    { 
      title: "จัดการธุรกิจ", 
      titleColor: textGradient("linear-gradient(90deg, #6600E9 25.6%, #A600E0 72.12%)"),
      key:"workspace.", 
      icon: workspaceImages.manageBusiness, 
      widthIcon: "w-9 h-9",
      desc:"ทุกอย่างในการจัดการและบริหารธุรกิจของคุณ",
      descColor: "#7D589E",
      sidebarMenus: [
        {
          title:"แนะนำสำหรับคุณ",
          icon:<Star01 className="text-[#FFD749]"/>,
          submenus: [
            {
              title:"สินค้าทั้งหมด",
              icon:<Archive className="text-[#DD2590]"/>,
              link:"",
            },
            {
              title:"เพิ่มสินค้าใหม่",
              icon:<ClipboardPlus className="text-[#00B14F]"/>,
              link:""
            },
            {
              title:"สินค้าในช่องทางต่างๆ",
              icon:<GitCompareArrows className="text-[#F98200]"/>,
              link:""
            }
          ]
        },
        {
          title:"สินค้าของคุณ",
          icon:<MessageCircle02 className="text-[#007AFF]"/>,
          submenus: [
            {
              title:"สินค้าทั้งหมด",
              icon:<Archive className="text-[#DD2590]"/>,
              link:"",
            },
            {
              title:"เพิ่มสินค้าใหม่",
              icon:<ClipboardPlus className="text-[#00B14F]"/>,
              link:""
            },
            {
              title:"สินค้าในช่องทางต่างๆ",
              icon:<GitCompareArrows className="text-[#F98200]"/>,
              link:""
            },
            {
              title:"หมวดหมู่สินค้า",
              icon:<ChartNoAxesColumnIncreasing className="text-[#BE8BFF] rotate-90"/>,
              link:""
            },
            {
              title:"ยี่ห้อสินค้า",
              icon:<AtSign className="text-[#1974FE]"/>,
              link:""
            },
            {
              title:"คลังสินค้า",
              icon:<ClipboardPlus className="text-[#FFD749]"/>,
              link:""
            },
            {
              title:"คุณลักษณะของสินค้า",
              icon:<Share2 className="text-[#FF63BE]"/>,
              link:""
            }
          ]
        },
        {
          title:"การค้าขาย",
          icon:<CoinsSwap01 className="text-[#FBBD43]"/>,
          submenus: [
            
          ]
        },
        {
          title:"ลูกค้า",
          icon:<Users03 className="text-[#15704A]"/>,
          submenus: [
  
          ]
        },
        {
          title:"การตลาด",
          icon:<Percent03 className="text-[#FF7E7E]"/>,
          submenus: [
  
          ]
        },
        {
          title:"ระบบแต้มและรางวัล",
          icon:<UserCog className="text-[#9E00E9]"/>,
          submenus: [
  
          ]
        },
        {
          title:"การเงิน",
          icon:<CircleDollarSign className="text-[#990025]"/>,
          submenus: [
  
          ]
        }
      ],
    },
    { 
      title: "WorkDay", 
      titleColor: textGradient("linear-gradient(90deg, #2D2D2D 0%, #E5E7E9 82.68%)"),
      key:"workday.", 
      icon: workspaceImages.workday, 
      widthIcon:"w-7 h-7",
      desc:"ระบบแชทกับทีมงานและสร้าง todo ติดตามงานและมอบงานให้ทีมของคุณ",
      descColor:"#3D3D3D",
      sidebarMenus: [
        {
          title:"Home",
          icon:<Bell />,
          submenus: [
            {
              title:"My Inbox",
              icon:<BellDot />,
              link:"",
            },
            {
              title:"Planner",
              icon:<Calendar />,
              link:""
            },
            {
              title:"Virtual Office",
              icon:<Building />,
              link:""
            },
            {
              title:"Contacts",
              icon:<BookUser />,
              link:""
            }
          ]
        },
        {
          title:"Chat",
          icon:<MessageCircle02 />,
          submenus: [
            {
              title:"All Chats",
              icon:<MessageDotsCircle />,
              link:"",
            },
            {
              title:"Threads",
              icon:<ListTree />,
              link:""
            },
            {
              title:"Pinned Chat",
              icon:<Bookmark />,
              link:""
            },
            {
              title:"Channels",
              icon:<MessagesSquare />,
              link:""
            }
          ]
        },
        {
          title:"Tasks Tracker",
          icon:<FileCheck01 />,
          submenus: [
            {
              title:"Create New Issue",
              icon:<GitPullRequestCreateArrow />,
              link:"",
            },
            {
              title:"My issues",
              icon:<BadgeAlert />,
              link:""
            },
            {
              title:"All issues",
              icon:<ListChecks />,
              link:""
            },
            {
              title:"Create New Project",
              icon:<FolderPen />,
              link:""
            }
          ]
        },
        {
          title:"Notes",
          icon:<File06 />,
          submenus: [
            {
              title:"Create Notes",
              icon:<CirclePlus />,
              link:"",
            },
            {
              title:"My Notes",
              icon:<NotepadText />,
              link:""
            },
            {
              title:"Create Note spaces",
              icon:<Grid2x2Check />,
              link:""
            }
          ]
        },
        {
          title:"Drive",
          icon:<CloudUpload />,
          submenus: [

          ]
        },
      ]
    },
    { 
      title: "เอกสาร", 
      titleColor: textGradient("linear-gradient(90deg, #18A0FB 0%, #E5E7E9 82.68%)"),
      key:"document.", 
      icon: workspaceImages.document, 
      widthIcon:"w-10 h-10",
      desc:"สร้างเอกสาร และ ข้อมูลต่างๆ เซฟและแชร์เอกสารให้กับทีมงานได้",
      descColor:"#2584C3",
      sidebarMenus: [
        {
          title:"My Documents",
          icon:<FilePen className="text-[#1BA2FB]"/>,
          submenus: [
            {
              title:"Create Document",
              icon:<SquarePen />,
              link:"",
            },
            {
              title:"My Documents",
              icon:<FileSearch01 />,
              link:ssoClick
            }
          ]
        },
        {
          title:"Template",
          icon:<FileSearch02 className="text-[#1BA2FB]"/>,
          submenus: [

          ]
        },
        {
          title:"Collections",
          icon:<Files className="text-[#1BA2FB]"/>,
          submenus: [

          ]
        },
        {
          title:"Journals",
          icon:<CalendarPlus className="text-[#1BA2FB]"/>,
          submenus: [

          ]
        },
        {
          title:"Tags",
          icon:<CloudBlank01 className="text-[#1BA2FB]"/>,
          submenus: [

          ]
        }
      ]
    },
    { 
      title: "พรีเซ็นเทชั่น", 
      titleColor: textGradient("linear-gradient(90.02deg, #14B1A1 0.01%, #147D73 68.64%)"),
      key: "presentation.", 
      icon: workspaceImages.presentationIcon, 
      widthIcon:"w-9 h-9",
      desc:"สร้างพรีเซ็นเทชั่น slides และผลงานที่คุณต้องการนำเสนอ",
      descColor:"#078F81",
      sidebarMenus: [
        {
          title:"My Presentations",
          icon:<PresentationChart02 className="text-[#14B1A1]"/>,
          submenus: [
            {
              title:"Create Presentation",
              icon:<CirclePlus className="text-[#14B1A1]"/>,
              link:"",
            },
            {
              title:"All my presentations",
              icon:<FolderOpen className="text-[#14B1A1]"/>,
              link:""
            }
          ]
        },
        {
          title:"Template",
          icon:<Files className="text-[#14B1A1]"/>,
          submenus: [

          ]
        },
      ]
    },
    { 
      title: "ไวท์บอร์ด", 
      titleColor: textGradient("linear-gradient(90deg, #FBBD43 0%, #FFE553 82.68%)"),
      key:"whiteboard.", 
      icon: workspaceImages.whiteboard, 
      widthIcon:"w-9 h-9",
      desc:"A single, AI-powered whiteboard that helps teams move faster.",
      descColor:"#8F6807",
      sidebarMenus: [
        {
          title:"Board home",
          icon:<SquareDashedBottom className="text-[#FBBD43]"/>,
          submenus: [
            {
              title:"Create Blank Board",
              icon:<CirclePlus className="text-[#FBBD43]"/>,
              link:"",
            },
            {
              title:"Create Flowchart",
              icon:<CirclePlus className="text-[#FBBD43]"/>,
              link:""
            },
            {
              title:"Create Intelligent Board",
              icon:<CirclePlus className="text-[#FBBD43]"/>,
              link:""
            },
            {
              title:"My boards",
              icon:<FolderOpen className="text-[#FBBD43]"/>,
              link:""
            }
          ]
        },
        {
          title:"Template",
          icon:<Files className="text-[#FBBD43]"/>,
          submenus: [

          ]
        },
      ]
    },
    { 
      title: "สร้างเว็บไซต์",
      titleColor: textGradient("linear-gradient(90deg, #F7235B 0%, #C60458 82.68%)"),
      key: "webstudio.", 
      icon: workspaceImages.manageWebsite, 
      widthIcon:"w-9 h-9",
      desc:"Create a customizable website or online store.",
      descColor:"#F57A9A",
      sidebarMenus: [
        {
          title:"WebStudio Home",
          icon:<SquareDashedBottom className="text-[#F7235B]"/>,
          submenus: [
            {
              title:"Create Web Page",
              icon:<CirclePlus className="text-[#F7235B]"/>,
              link:"",
            },
            {
              title:"Website Settings",
              icon:<CirclePlus className="text-[#F7235B]"/>,
              link:""
            },
            {
              title:"All pages",
              icon:<FolderOpen className="text-[#F7235B]"/>,
              link:""
            }
          ]
        },
        {
          title:"Template",
          icon:<Files className="text-[#F7235B]"/>,
          submenus: [

          ]
        },
      ]
    },
  ]

  return { dashboardTriggers, titleModal, setTitleModal, setSubmenuIndex, submenuIndex, submenusList }
}

export default useDashboardActivities
