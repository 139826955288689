import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from "react-query";
import UpgradeProButton from './upgradeProButton'
import { site } from "../../client/api";
import { Skeleton } from "../ui/skeleton"
import { motion } from 'framer-motion';
import { Button } from '../ui/button'
import { Icons } from '../ui/icons'
import { PanelLeftClose, PanelLeftOpen } from 'lucide-react'
import { usePostHog } from 'posthog-js/react';
import { useUser } from 'src/hooks/useUser';
import useModal from 'src/hooks/useModal';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { cn } from 'src/lib/utils';
import AppsListModal from './components/AppsListModal';
import IframeModal from '../modals/iframeModal';
import { DotsGrid } from "@untitled-ui/icons-react/build/cjs"

export default function Topbar({ isSidebarOpen, setIsSidebarOpen }) {
  const { t } = useTranslation()
  const { user, auth, logout } = useUser();
  const posthog = usePostHog();
  const { data: sites } = useQuery('sites', site.list, {
    enabled: !!user,
  });

  const location = useLocation()
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search);
  const { open, openModal } = useModal();
  const [currentLocation, setCurrentLocation] = useState(0);
  const [isIframeOpen, setIsIframeOpen] = useState(false)

  const { data: loadAdmin, refetch } = useQuery('loadAdmin', () => site.login(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name, 'Admin'), {
    enabled: false,
    onSuccess: (res) => {
      //console.log(res);
    },
  });

  useEffect(() => {
    if (user && sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name && !loadAdmin) {
      refetch();
    }
  }, [user, sites, refetch]);

  const OpenPage = (page, app = "app") => {
    // var sid = loadAdmin?.data?.message.sid;
    var sitetoview = sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name;
    window.open(`https://${sitetoview}/${app}/${page}`, '_blank');
  }

  const menus = [
    { title: "Home", link: "/dashboard/app" },
    { title: "WorkSpace", link: "/dashboard/workspace" },
    { title: "My Storefront", onClick: () => OpenPage("", "store") },
    { title: "Teams", onClick: () => openModal('/teams/member') }
  ]

  useEffect(() => {
    if (params.get('modal') === 'team' && !open) {
      openModal('/teams/member')
    }
  }, [])

  const ActiveMenu = ({ width, left }) => {
    return (
      <motion.div
        animate={{
          x: left,
          y: 0,
          scale: 1,
          rotate: 0
        }}
        style={{
          width: width,
          height: "36px",
          backgroundColor: "white",
          borderRadius: "9999px",
          position: "absolute",
          zIndex: 5
        }} className='active-menu-te'/>
    )
  }

  const ToggleIcon = isSidebarOpen ? PanelLeftClose : PanelLeftOpen

  var sid = loadAdmin?.data?.message.sid;
  var sitetoview = sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name;

  return (
    <nav className="topbar">
      <ToggleIcon className='text-[#424149DB] cursor-pointer' onClick={() => setIsSidebarOpen(!isSidebarOpen)}/>
      <section className={cn('flex justify-center col-span-4')}>
        <div className={cn('flex items-center bg-lightgray-100 p-1 font-medium relative w-fit overflow-hidden rounded-full gap-2 h-[46px]')}>
          {menus.map((menu, index) => {
            return (
              <Button
                key={index}
                onClick={() => menu.onClick ? menu.onClick() : navigate(menu.link)}
                id={`menu-${index}`}
                className={`px-5 py-2 shadow-none h-full !bg-transparent relative rounded-full z-5 text-base font-semibold ${(menu.link === "/dashboard/workspace" ? location.pathname.includes('workspace') : location.pathname === menu.link) ? 'text-lightblue !bg-white shadow-topbar' : 'text-lightgray-850'}`}
              >{menu.title}</Button>
            )
          })}

          <Icons.topbarIcon className="cursor-pointer min-w-fit min-h-fit" onClick={() => setIsIframeOpen(true)}/>
          <IframeModal 
            iframeSrc={`https://${sitetoview}/app?sid=${sid}&posthog_session_id=${posthog.get_session_id()}`}
            isOpen={isIframeOpen}
            onClose={() => setIsIframeOpen(false)}
          />
          {/* <ActiveMenu width="60px" left="0"/> */}
        </div>
      </section>
      <section className='flex justify-end col-span-1 items-center gap-[15px]'>
        <UpgradeProButton />
        <AppsListModal trigger={<DotsGrid className="text-[#007AFF] h-7 w-7"/>}/>
        <Avatar className='w-8 h-8 text-sm cursor-pointer' onClick={openModal}>
          <AvatarImage src="" />
          <AvatarFallback>{user?.first_name[0]}</AvatarFallback>
        </Avatar>
      </section>
    </nav>
  )
}