import CloudLogo from "src/components/icon-menus/CloudLogo"
import { AccountModalMenus } from "./AccountModalMenus"
import UpgradeLogo from "src/components/icon-menus/UpgradeLogo"
import { Check } from "@untitled-ui/icons-react/build/cjs"
import { Button } from "src/components/ui/button"
import ICloud from "src/components/icon-menus/upgrade-details/iCloud"
import AppleTV from "src/components/icon-menus/upgrade-details/AppleTV"
import AppleArcade from "src/components/icon-menus/upgrade-details/AppleArcade"
import { site } from "src/client/api";
import { useUser } from "src/hooks/useUser";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { Skeleton } from "src/components/ui/skeleton"

const Upgrade = () => {
  const plan_advantages = [
    { text: "Get 20GB Space", icon: <ICloud /> },
    { text: "Add up to 999 items", icon: <AppleTV /> },
    { text: "3000 orders/month", icon: <AppleArcade /> },
    { text: "Include 2 Admins", icon: <AppleArcade /> }
  ]

  const { user } = useUser();
  const { data: sites } = useQuery('sites', site.list, {
    enabled: !!user,
  });
  const { data: siteOverview } = useQuery(['site', `${sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name}`], () => site.overview(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), {
    enabled: !!sites?.site_list.length
  });

  const checkout_info = useQuery('checkout_info', () => site.get_web_plans(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), { enabled: false });
  const pro_details = checkout_info?.data && checkout_info?.data.find(item => item.name === 'pro');
  const plan = siteOverview?.plan?.current_plan;
  const navigate = useNavigate();

  useEffect(() => {
    if (user && sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name && !checkout_info.data) {
      checkout_info.refetch();
    }
  }, [checkout_info, sites]);

  return (
    <section className="flex flex-col gap-12 items-center">
      <div className="flex items-center justify-between">
        <h2 className="text-[27px] font-medium tracking-[-0.45px] w-1/2 leading-[35px]">Get more usages and increase quota of the platform by going Pro.</h2>
        <CloudLogo />
      </div>

      <AccountModalMenus largeTitle="Upgrade plan" className="px-4 py-5 flex flex-col gap-5">
        <div className="flex items-center justify-between text-xl font-bold">
          <span>Get <UpgradeLogo /></span>
          <h2 className="flex items-center gap-3.5">{pro_details?.price_usd ? <>THB {pro_details?.price_usd}</> : <Skeleton className="h-6 w-[120px]"/>}
            <span className="h-6 w-6 bg-link rounded-full flex items-center justify-center">
              <Check className="text-white h-4 w-4" />
            </span>
          </h2>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="text-base font-semibold">Begin by creating your site, upgrade anytime.</h2>
          <p className="text-lightgray-900/60 text-sm">Digitalize your business, manage everything in one platform, Get advanced features designed specifically for businesses. Upgrade when you want to, cancel anytime. A bespoke plan that meets the complexity of your operations.</p>
        </div>

        <ul className="flex flex-col gap-3 mt-4">
          {plan_advantages.map(advantage => (
            <li className="flex items-center gap-4">
              {advantage.icon}
              {advantage.text}
            </li>
          ))}
        </ul>
      </AccountModalMenus>

      <Button className="regis-btn" onClick={() => navigate("/upgrade/checkout")}>Confirm</Button>
    </section>
  )
}

export default Upgrade