import CheckoutScan from "./checkoutScan";
import CheckoutInfo from "./checkoutInfo";
import { useState, useEffect } from 'react'
import { useQuery } from "react-query";
import { site } from "../../client/api";
import { useParams } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import CheckoutConfirm from "./checkoutConfirm";

export default function CheckoutPage(){
  const { id,invoice_id } = useParams()
  const [subtotal, setSubtotal] = useState(0)
  const [vat, setUservat] = useState(0)
  const [discount, setDiscount] = useState(0)
  const { user, auth, logout } = useUser();
  const { data: sites } = useQuery('sites', site.list, {
    enabled: false
  });
  const [invoice_info, setInvoiceInfo] = useState([])
  

  const get_invoice = async (invoice_id) => {
    try {
      const invoice_info = await site.get_invoice(invoice_id);
      if(invoice_info){
        setInvoiceInfo(invoice_info);
      }
      return invoice_info;
    } catch (error) {
      // Handle error here
    }
  }



  useEffect(() => {
    get_invoice(invoice_id);
  }, [invoice_id]);


  if (!subtotal) {
    if (invoice_info?.total) {
      const vatRate = 0.07; // 7% VAT
      const priceWithoutVat = invoice_info?.total / (1 + vatRate); // Exclude VAT
      const roundedSubtotal = Math.round(priceWithoutVat * 100) / 100; // Round to 2 decimal places
      const userVat = invoice_info?.total - roundedSubtotal; // Calculate VAT
      setSubtotal(roundedSubtotal); // Set subtotal excluding VAT
      setUservat(Math.round(userVat * 100) / 100); // Set VAT amount, rounded to 2 decimal places
    }
  }

  const total = () => {
    if (discount){
      return Number(((subtotal - discount) + vat).toLocaleString())
    } else {
      return Number((subtotal + vat).toLocaleString())
    }
  };

  const [paymentConfirm, setPaymentConfirm] = useState(false)

  return (
    <div className="page-container flex md:gap-x-10 flex-col md:flex-row">
      {paymentConfirm ? (
        <div className="max-w-[500px] mx-auto">
          <CheckoutConfirm InvoiceDate={invoice_info?.modified} invoiceId={invoice_id} paymentConfirm={paymentConfirm} setPaymentConfirm={setPaymentConfirm} totalPrice={`฿ ${total()}`}/>
        </div>
      ) : (
        <CheckoutInfo invoiceId={invoice_id} paymentConfirm={paymentConfirm} setPaymentConfirm={setPaymentConfirm} totalPrice={total()} discount={discount} vat={vat} subtotal={subtotal}/>
      )}
    </div>
  )
}