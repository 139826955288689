import { BillingAddressForm } from "src/components/billingAddressForm";
import { useUser } from "src/hooks/useUser";

const BillingAddress = () => {
  const { team } = useUser();

  return (
    <BillingAddressForm billingAddress={team} />
  )
}

export default BillingAddress