import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../components/sidebar/sidebar";
import { useEffect, useState } from "react";
import Topbar from "src/components/topbar/topbar";
import { cn } from "src/lib/utils";

export default function DashboardLayout() {
  const location = useLocation()
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    localStorage.getItem("sidebar-open") === "true" ? true : false
  );

  useEffect(() => {
    localStorage.setItem("sidebar-open", String(isSidebarOpen))
  }, [isSidebarOpen, localStorage.getItem("sidebar-open")])

  return (
    <>
      {location.pathname !== '/dashboard/instance-configuration' ? (
        <section className={cn("outer-page", {"active": isSidebarOpen})}>
          <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
          <section className="relative">
            <Topbar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
            <div className={cn("page-section")}>
              <Outlet />
            </div>
          </section>
        </section>
      ) : <Outlet />}
    </>
  );
}