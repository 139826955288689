import { useEffect, useRef, useState } from "react";
import { MainModal } from "src/components/modals/MainModal";
import { Button } from "src/components/ui/button";
import { ChevronLeft, ChevronRight, ChevronsRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useDashboardActivities from "src/hooks/useDashboardActivities";
import { cn } from "src/lib/utils";

const DashboardTabs = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const triggerRef = useRef(null)
  const tabBtnClassName = "font-medium !px-4 !py-[10px] text-sm text-darkergray-300 !bg-[#F6F6F6] !shadow-none !rounded-[66px] leading-5 flex items-center gap-2 h-[55px]";
  const [isWorkspaceModalOpen, setIsWorkspaceModalOpen] = useState(false)
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);
  const { dashboardTriggers, setSubmenuIndex, titleModal, setTitleModal, submenusList } = useDashboardActivities()

  const handleOpenModal = (trigger) => {
    setIsWorkspaceModalOpen(true);
    setTitleModal(trigger)
  }

  const handleCloseModal = () => {
    setIsWorkspaceModalOpen(false)
    setTimeout(() => setSubmenuIndex(0), 300)
  }

  const { titleColor, key, desc, descColor, sidebarMenus } = titleModal
  const updateButtonVisibility = () => {
    const { scrollLeft, scrollWidth, clientWidth } = triggerRef.current;
    setShowLeftButton(scrollLeft > 0);
    setShowRightButton(scrollLeft < scrollWidth - clientWidth);
  };

  useEffect(() => {
    if (triggerRef !== null){
      const setWidth = () => {
        if (triggerRef.current.offsetWidth > 900){
          setShowLeftButton(false)
          setShowRightButton(false)
        } else {
          updateButtonVisibility()
        }
      }
      setWidth()
      window.addEventListener("resize", setWidth)
      triggerRef.current.onscroll = () => updateButtonVisibility()
      return () => { window.removeEventListener("resize", setWidth) }
    }
  }, [triggerRef])

  const handleScrollTab = (direction, pos, ref = triggerRef) => {
    if (direction === "left"){
      if (ref.current.scrollLeft - pos > 0){
        ref.current.scrollBy({ left: -pos, behavior: 'smooth' });
      } else {
        ref.current.scrollLeft = 0;
      }
    } else if (direction === "right"){
      let { scrollWidth, clientWidth } = ref.current;
      const maxScrollLeft = scrollWidth - clientWidth;
      if (ref.current.scrollLeft + pos < maxScrollLeft){
        ref.current.scrollBy({ left: pos, behavior: 'smooth' });
      } else {
        ref.current.scrollLeft = maxScrollLeft
      }
    }
    setTimeout(updateButtonVisibility, (pos + 50));
  }

  return (
    <>
      <section className="relative flex items-center mb-6">
        <button 
          className={cn("arrow-tab-btn -left-4 invisible opacity-0", {"visible opacity-1": showLeftButton})}
          onClick={() => handleScrollTab("left", 200)}
        >
          <ChevronLeft />
        </button>
        <button 
          className={cn("arrow-tab-btn -right-4 invisible opacity-0", {"visible opacity-1": showRightButton})}
          onClick={() => handleScrollTab("right", 200)}
        >
          <ChevronRight />
        </button>
        <div className="w-full overflow-auto relative scroll-smooth" ref={triggerRef}>
          <div className="flex gap-4 items-center justify-center min-w-max">
            {dashboardTriggers.map(trigger => (
              <Button key={trigger.key} className={tabBtnClassName} onClick={() => handleOpenModal(trigger)}>
                <img src={trigger.icon} className={trigger.widthIcon} alt={trigger.title}/>
                {trigger.title}
              </Button>
            ))}
          </div>
        </div>
      </section>

      <MainModal open={isWorkspaceModalOpen} onClose={handleCloseModal} type="workspace" mainClassName="p-0">
        <section className="flex h-[752px]">
          <div className="bg-primary-foreground h-full w-1/3">
            <div className="p-[30px] pt-5 border-b border-b-[#ECECEC]">
              <h1 className="text-[32px] font-semibold sf-pro tracking-[-1.33px]" style={titleColor}>{key}</h1>
              <p className="font-medium text-sm leading-5" style={{ color: descColor }}>{desc}</p>
            </div>
            <nav className="p-[22px]">
              <ul className="flex flex-col gap-4">
                {sidebarMenus?.map((menu, index) => 
                  <li key={menu} className="flex items-center gap-4 p-2 cursor-pointer" onClick={() => setSubmenuIndex(index)}>
                    {menu.icon}
                    <p className="text-secondary-foreground font-medium text-[17px]">{menu.title}</p>
                  </li>
                )}
                <li className="flex items-center gap-4 p-2 cursor-pointer" onClick={() => navigate(`/dashboard/workspace${key !== "workspace." ? `/${key.slice(0, -1)}` : ""}`)}>
                  <ChevronsRight />
                  <p className="text-secondary-foreground font-medium text-[17px]">{t("More")}</p>
                </li>
              </ul>
            </nav>
          </div>
          <div className="w-2/3 bg-white relative z-[9] flex flex-col gap-[30px] px-6 py-10">
            {submenusList ? (
              <>
                <h2 className="text-secondary-foreground text-lg font-medium">{submenusList?.title}</h2>
                <ul className="flex flex-col gap-4">
                  {submenusList?.submenus?.map(menu => (
                    <li key={menu} className="p-[14px] bg-primary-foreground rounded-lg flex justify-between items-center cursor-pointer" onClick={menu.link}> {/* onClick={() => window.location.href = menu.link} */}
                      <div className="flex items-center gap-4">
                        {menu.icon}
                        <p className="text-darkergray-200 font-medium">{menu.title}</p>
                      </div>
                      <ChevronRight className="text-lightgray-900/30"/>
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
          </div>
        </section>
      </MainModal>
    </>
  )
}

export default DashboardTabs