import { Button } from "src/components/ui/button"
import mainDashboardBanner from "src/img/main-dashboard-banner.png"

export default function DashboardBanner() {

  const bgStyles = {
    background:`url(${mainDashboardBanner})`,
    backgroundRepeat:"no-repeat",
    backgroundSize:"cover"
  }

  return (
    <section className="flex flex-col gap-2 p-[45px] h-[237px] rounded-[22px] text-white align-center justify-center text-center content-box" style={bgStyles}>
      <h3 className="text-base font-medium">ซาเวียโกทำอะไรบ้าง? รู้ยังว่าทำได้เยอะนะ!</h3>
      <div className="flex items-center justify-center gap-3">
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_1310_2203" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="54" height="54">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M54 27C54 12.0884 41.9116 0 27 0C12.0884 0 0 12.0884 0 27C0 41.9116 12.0884 54 27 54C41.9116 54 54 41.9116 54 27ZM30.9952 23.0045C33.2146 25.2239 36.5845 25.6956 43.3245 26.6393L45.9 27L43.3245 27.3605C36.5845 28.3041 33.2146 28.7758 30.9952 30.9952C28.7761 33.2146 28.3041 36.5845 27.3605 43.3245L27 45.9L26.6396 43.3245C25.6959 36.5845 25.2239 33.2146 23.0048 30.9952C20.7854 28.7758 17.4155 28.3041 10.6755 27.3605L8.1 27L10.6755 26.6393C17.4155 25.6956 20.7854 25.2239 23.0048 23.0045C25.2242 20.7851 25.6959 17.4155 26.6396 10.6755L27 8.1L27.3605 10.6753C28.3041 17.4153 28.7761 20.7854 30.9952 23.0045Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_1310_2203)">
            <g style={{mixBlendMode:"overlay"}}>
            <path d="M54 0H0V54H54V0Z" fill="white"/>
            </g>
          </g>
        </svg>
        <h1 className="text-[50px] font-semibold tracking-[-2px]">discover zaviago</h1>
      </div>
      <Button className="w-fit font-semibold !bg-white text-[#06090D] shadow-topbar mx-auto text-base px-5 h-10">See what zaviago can do for you</Button>
    </section>
  )
}