import shopeeIcon from "src/img/shopee-icon.svg"
import shopeeBanner from "src/img/shopee-banner.svg"
import lazadaIcon from "src/img/lazada-icon.svg"
import lazadaBanner from "src/img/lazada-banner.svg"
import FeatureCard from "../FeatureCard"

const WorkspaceBanner = () => {
  return (
    <section className="flex flex-col gap-y-5">
      <h2 className="text-[#222222] text-xl font-bold">แนะนำระบบเชื่อมต่อต่างๆ</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8">
        <FeatureCard titleColor="#CE3820" title="จัดการคำสั่งซื้อในที่เดียว" desc="ระบบ เชื่อมต่อ ช็อปปี้" featureName="Shopee integration" category="Marketplaces" icon={shopeeIcon} image={shopeeBanner} isCover={true}/>
        <FeatureCard titleColor="#F501EE" title="จัดการคำสั่งซื้อในที่เดียว" desc="ระบบ เชื่อมต่อ ลาซาด้า" featureName="Lazada integration" category="Marketplaces" icon={lazadaIcon} image={lazadaBanner} isCover={true}/>
      </div>
    </section>
  )
}

export default WorkspaceBanner