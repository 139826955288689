import { ChevronRight } from "lucide-react"

const AdditionalBtn = ({ link, text, outerBg, innerBg, img, imagePadding }) => {
  return (
    <button className={`min-w-[188px] overflow-hidden relative rounded-xl h-[246px] ${outerBg}`} onClick={() => window.open(`https://${link}`, "_blank")}>
      <img src={img} alt="All templates" className="absolute top-0" style={{padding: imagePadding}}/>

      <p className={`absolute bottom-0 ${innerBg} w-full py-7 text-white text-base font-bold flex items-center gap-x-2 justify-center backdrop-blur overflow-hidden rounded-b-xl`}>
        {text}
        <ChevronRight className="h-4 w-4"/>
      </p>
    </button>
  )
}

export default AdditionalBtn