import { useTranslation } from "react-i18next"
import { CheckCircle2, ClipboardList, Package, Users } from "lucide-react"
import AdvantageCard from "src/components/AdvantageCard"

export default function DashboardCategories(){
  const { t } = useTranslation();
  const iconClassName = "stroke-1 w-7 h-7";
  const cates = [
    { title: "จัดการธุรกิจ", desc: "จัดการสินค้าของคุณในระบบ", icon: <Package className={iconClassName}/> },
    { title: "จัดการคำสั่งซื้อ", desc: "ติดตามสถานะและจัดการคำสั่งซื้อของคุณ", icon: <ClipboardList className={iconClassName}/> },
    { title: "การแจ้งโอนเงิน", desc: "ตรวจสอบธุรกรรมการโอนเงินจากลูกค้า", icon: <CheckCircle2 className={iconClassName}/> },
    { title: "ลูกค้า", desc: "ดู, เพิ่ม, และอัปเดตข้อมูลลูกค้าของคุณ", icon: <Users className={iconClassName}/> },
  ]
  return (
    <section className="flex flex-col gap-y-5">
      <h2 className="text-darkergray-100 text-xl font-bold">หมวดหมู่การทำงานต่างๆ</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {cates.map(cate => (
          <AdvantageCard key={cate.title} title={cate.title} desc={cate.desc} icon={cate.icon}/>
        ))}
      </div>
    </section>
  )
}