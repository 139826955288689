const PrivacyPolicyPage = () => {

  const linkClassName = "text-link hover:underline"
  const PrivacyList = ({ title, desc }) => (
    <h2>
      <span className="text-2xl font-bold block mb-3">{title}</span> 
      <p className="leading-6">{desc}</p>
    </h2>
  )

  const SubprivacyList = ({ title, desc }) => (
    <li>
      <span className="text-lg font-bold block mb-2">{title}</span>
      <p className="leading-6">{desc}</p>
    </li>
  )

  const privacy_notice_list = [
    {
      title: "1. สิทธิตามกฎหมายของท่าน",
      desc: "เพื่อให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (2019) บริษัทขอแจ้งให้คุณทราบว่าข้อมูลส่วนบุคคลของคุณจะถูกรวบรวมและนำไปใช้ตามวัตถุประสงค์ดั้งเดิม โดยคุณมีสิทธิ์ดังต่อไปนี้ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (2019) บริษัทขอให้ท่านมั่นใจว่าข้อมูลส่วนบุคคลของคุณจะถูกเก็บไว้อย่างปลอดภัยตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (2019)",
      sublist: [
        {
          title: "สิทธิในการเข้าถึงข้อมูล",
          desc: "ท่านมีสิทธิที่จะแจ้งขอการเข้าถึงและรับสำเนาข้อมูลส่วนบุคคลของคุณ หรือร้องขอให้มีการเปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ได้รับโดยไม่ได้รับความยินยอมจากท่าน เว้นแต่จะไม่สามารถทำได้ด้วยด้วยข้อจำกัดทางเทคนิค"
        },
        {
          title: "สิทธิในการขอแก้ไขข้อมูล",
          desc: "ท่านมีสิทธิที่จะขอให้บริษัทแก้ไขข้อมูลของท่าน ในกรณีที่ข้อมูลของท่านไม่ถูกต้องหรือไม่ครบถ้วน"
        },
        {
          title: "สิทธิในการคัดค้านการเก็บรวบรวม ใช้หรือเปิดเผยข้อมูล",
          desc: "ท่านมีสิทธิคัดค้านการรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล"
        },
        {
          title: "สิทธิ์ในการเพิกถอนความยินยอม",
          desc: "หากคุณยินยอมให้เราเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของคุณ คุณมีสิทธิ์ที่จะเพิกถอนปฎิเสธความยินยอมของคุณได้ทุกเมื่อ"
        },
        {
          title: "สิทธิในการลบข้อมูล",
          desc: "คุณมีสิทธิ์ที่จะขอให้เราลบ ทำลาย หรือไม่เปิดเผยข้อมูลส่วนบุคคลของคุณ ตามขอบเขตที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลอนุญาต"
        },
        {
          title: "สิทธิในการยื่นคำร้อง",
          desc: "คุณมีสิทธิ์ที่จะขอให้เราลบ ทำลาย หรือไม่เปิดเผยข้อมูลส่วนบุคคลของคุณ ตามขอบเขตที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลอนุญาต"
        }
      ]
    },
    {
      title: "2. การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูล",
      desc: "เพื่อประสบการณ์ที่ดีขึ้นในขณะที่ใช้บริการของบริษัท บริษัทอาจขอให้คุณจำเป็นต้องให้ข้อมูลส่วนบุคคลที่ระบุถึงตัวตนของคุณแก่เรา ไม่เพียงแต่ชื่อ ที่อยู่อีเมล หมายเลขโทรศัพท์ ตำแหน่งคร่าวๆ ประวัติการค้นหา ID ผู้ใช้งาน ID อุปกรณ์ที่เข้าใช้งาน การโต้ตอบผลิตภัณฑ์ ข้อมูลความผิดพลาด ข้อมูลที่เราร้องขอจะถูกเก็บไว้โดยเราและใช้ตามคำอธิบายไว้ในนโยบายความเป็นส่วนตัวนี้",
      sublist: []
    },
    {
      title: "3. ประเภทข้อมูลและวัตถุประสงค์การใช้ข้อมูล",
      desc: "เพื่อทำให้เว็บไซต์ซาเวียโกจำกัดและบริการสามารถให้ท่านใช้งานได้หรือเพื่อปฏิบัติตามกฎหมาย เราจึงมีความจำเป็นจะต้องเก็บรวบรวมข้อมูลส่วนบุคคลของท่านบางส่วน โดยบริษัทจะเก็บรวบรวมใช้และเปิดเผยข้อมูลส่วนบุคคลของท่าน ดังต่อไปนี้",
      sublist: [
        {
          title: "ข้อมูลรายละเอียดส่วนตัว:",
          desc: "ชื่อ-นามสกุล, วัน/เดือน/ปีเกิด, หมายเลขบัตรประจำตัวประชาชน, เลขประจำตัวผู้เสียภาษี"
        },
        {
          title: "ข้อมูลการติดต่อ:",
          desc: "ที่อยู่, หมายเลขโทรศัพท์, อีเมล และ LINE"
        },
        {
          title: "ข้อมูลเกี่ยวกับการทำธุรกรรมและข้อมูลทางการเงิน:",
          desc: "ประวัติการใช้บริการ, หมายเลขบัญชีธนาคาร, หมายเลขบัตรเครดิต"
        },
        {
          title: "ข้อมูลเกี่ยวกับการใช้งานระบบ คอมพิวเตอร์:",
          desc: "หมายเลขประจำเครื่องคอมพิวเตอร์ (IP Address), บัญชีผู้ใช้งาน, คุกกี้, ข้อมูลและประวัติการเข้าถึงแอปพลิเคชั่น และเว็บไซต์ของบริษัท"
        }
      ],
      footerDesc: "อย่างไรก็ตาม หากท่านไม่ให้เราเก็บรวบรวมข้อมูลที่เราร้องขอ เราอาจจะไม่สามารถจัดเตรียมผลิตภัณฑ์หรือบริการที่ท่านร้องขอได้ ข้อมูลส่วนบุคคลที่เราได้เก็บรวบรวมจากท่านดังที่กล่าวไว้ข้างต้น อาจนำมาใช้สำหรับวัตถุประสงค์ดังต่อไปนี้",
      footerSublist: [
        "เพื่อสร้างและจัดการบัญชีผู้ใช้งาน",
        "เพื่อทำการและจัดการคำสั่งซื้อ",
        "เพื่อจัดส่งผลิตภัณฑ์หรือบริการ",
        "เพื่อปรับปรุงผลิตภัณฑ์หรือบริการให้ดียิ่งขึ้น",
        "เพื่อส่งข้อมูลการดูแลจัดการ",
        "เพื่อการนำเสนอสิทธิประโยชน์หรือบริการอื่น ๆ ของบริษัท การส่งข้อเสนอเกี่ยวกับผลิตภัณฑ์และการบริการ รวมถึงโปรโมชันต่าง ๆ ในการส่งเสริมกิจกรรมทางการตลาดและรายการส่งเสริมการขาย",
        "เพื่อตอบสนองต่อข้อสงสัยและนำเสนอการช่วยเหลือ",
        "เพื่อร้องขอข้อเสนอแนะจากผู้ใช้",
        "เพื่อปรับปรุงประสบการณ์การใช้งานของผู้ใช้ให้ดียิ่งขึ้น",
        "เพื่อโพสต์หรือประชาสัมพันธ์ความพึงพอใจของท่านที่มีเกี่ยวกับผลิตภัณฑ์และบริการของเรา",
        "เพื่อบังคับใช้ข้อตกลงและเงื่อนไขและนโยบายต่าง ๆ",
        "เพื่อดำเนินการและจัดการเว็บไซต์ซาเวียโกและบริการ",
        "เพื่อมีส่วนร่วมในกิจกรรมอื่นใดที่เกี่ยวข้องกับเว็บไซต์และบริการของซาเวียโก"
      ]
    },
    {
      title: "4. การเก็บข้อมูลและเวลาการเก็บข้อมูล",
      desc: "บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของท่านในระหว่างที่ท่านเป็นลูกค้าของบริษัท และเมื่อท่านสิ้นสุดความสัมพันธ์กับบริษัท (เช่น หลังจากที่ท่านปิดบัญชีที่มีอยู่กับบริษัท หรือนับแต่การทำธุรกรรมกับบริษัท หรือกรณีบริษัทปฏิเสธคำขอใช้บริการของท่าน หรือท่านขอยกเลิกการใช้บริการของบริษัท) บริษัทจะเก็บข้อมูลส่วนบุคคลของท่านตามระยะเวลาที่เหมาะสมและจำเป็นสำหรับข้อมูลส่วนบุคคลแต่ละประเภทและวัตถุประสงค์ตามที่พรบ.คุ้มครองข้อมูลส่วนบุคคลกำหนด"
    },
    {
      title: "5. การใช้คุกกี้",
      desc: (<>
        คุกกี้คือไฟล์ข้อมูลขนาดเล็กซึ่งโดยทั่วไปจะถูกใช้อย่างไม่ระบุตัวตน ไม่บันทึกข้อมูลส่วนบุคคลของบุคคลใดบุคคลหนึ่งเฉพาะ โดยที่จะเก็บข้อมูลเท่าที่จำเป็นในเครื่องคอมพิวเตอร์ของเจ้าของข้อมูลไว้ชั่วคราว นอกจากนี้ข้อมูลเหล่านี้จะถูกส่งจากเบราว์เซอร์ของคุณไปยังเว็บไซต์ที่คุณเยี่ยมชม เพื่ออำนวยความสะดวกในการติดต่อสื่อสาร รวมถึงเพื่อเพิ่มประสิทธิภาพในการใช้งานเว็บไซต์ให้ดียิ่งขึ้น และเพื่อให้ได้รับการบริการที่ดีที่สุด<br/>
        บริการของบริษัทไม่ได้ใช้ “คุกกี้” เหล่านี้อย่างชัดเจน อย่างไรก็ตาม แอปอาจใช้รหัสบุคคลที่สามและชุดคำสั่งที่ใช้ “คุกกี้” เพื่อรวบรวมข้อมูลและปรับปรุงบริการของพวกเรา ท่านมีทางเลือกที่จะยอมรับหรือปฏิเสธคุกกี้เหล่านี้และรู้ว่าเมื่อใดที่คุกกี้จะถูกส่งไปยังอุปกรณ์ของท่าน หากท่านเลือกปฏิเสธคุกกี้ของเรา ท่านอาจไม่สามารถใช้บริการบางส่วนได้ สำหรับรายละเอียดเพิ่มเติม ท่านสามารถตรวจสอบได้ที่ นโยบายการใช้คุ้กกี
      </>)
    },
    {
      title: "6. ความปลอดภัยของข้อมูล",
      desc: "บริษัทมีการใช้มาตรการรักษาความปลอดภัยภายในบริษัทและการใช้บังคับนโยบายอย่างเข้มงวดในการเก็บรักษาข้อมูลส่วนบุคคลของท่านให้ปลอดภัย ซึ่งรวมถึงการเข้ารหัสข้อมูล และมาตรการป้องกันการเข้าถึงข้อมูล โดยบริษัทกำหนดให้บุคลากรของบริษัทต้องปฏิบัติตามมาตรฐานและนโยบายความเป็นส่วนบุคคลที่เหมาะสม รวมถึงจะต้องจัดให้มีการดูแลรักษาข้อมูลและมีมาตรการที่เหมาะสมในการใช้หรือการส่งหรือโอนข้อมูลส่วนบุคคลของท่าน"
    },
    {
      title: "7. วิธีการติดต่อบริษัท",
      desc: (<>
        หากท่านมีข้อสงสัยหรือข้อเสนอแนะเกี่ยวกับนโยบายความเป็นส่วนตัวของบริษัท  โปรดติดต่อบริษัทตามช่องทางดังต่อไปนี้<br/>
        อีเมล: <a href="mailto:service@zaviago.com" className={linkClassName}>service@zaviago.com</a> หรือ บริษัท ซาเวียโก จำกัด (สำนักงานใหญ่)<br/>
        999/99 ถนนพระราม 9 แขวงสวนหลวง เขตสวนหลวง<br/>
        กรุงเทพมหานคร 10250 ( 09.00 - 18.00 น.)
      </>)
    },
    {
      title: "8. การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว",
      desc: <>บริษัทอาจมีการเปลี่ยนแปลงนโยบายความเป็นส่วนตัวในอนาคต อย่างไรก็ตาม ท่านสามารถตรวจสอบนโยบายความเป็นส่วนตัวฉบับปัจจุบันได้ที่เว็บไซต์ของบริษัท <a href="https://zaviago.com" className={linkClassName}>www.zaviago.com</a></>
    }
  ]

  return (
    <main className="text-base max-w-5xl mx-auto space-y-12 p-10">
        <section className="space-y-6">
            <h1 className="text-3xl font-bold text-center leading-9">ประกาศนโยบายความเป็นส่วนตัว (Privacy Notice)</h1>
            <div className="space-y-3 leading-6">
              <p>บริษัท ซาเวียโก จำกัด (“บริษัท”) ขอบพระคุณผู้ใช้งานทุกท่านที่ไว้ใจในบริการของเรา ความปลอดภัยและข้อมูลส่วนบุคคลของผู้ใช้งานถือเป็นสิ่งสำคัญที่บริษัทดูแลและใส่ใจเป็นอย่างยิ่ง</p>
              <p>บริษัทจึงจัดทำประกาศนโยบายความเป็นส่วนตัวนี้ เพื่อแจ้งให้ลูกค้าของบริษัทรับทราบถึงนโยบายของบริษัทเกี่ยวกับการเก็บรวบรวม การใช้ และการเปิดเผยข้อมูลส่วนบุคคลของบุคคลธรรมดา (“ท่าน”) ซึ่งเป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 โดยประกาศนโยบายความเป็นส่วนตัวนี้ประกอบไปด้วยสิทธิตามกฎหมายของท่าน วิธีการที่บริษัทเก็บ ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน ประเภทข้อมูลและวัตถุประสงค์ในการดำเนินการดังกล่าว รวมถึงรายละเอียดเกี่ยวกับระยะเวลาในการเก็บรักษาข้อมูล การรักษาความลับและความปลอดภัยของข้อมูลส่วนบุคคลของท่าน และวิธีการติดต่อบริษัท</p>
            </div>
            {privacy_notice_list.map(list => (
              <section className="space-y-4">
                <PrivacyList title={list.title} desc={list.desc}/>
                
                <ul className="ml-6 list-disc space-y-2 flex flex-col leading-6">
                  {list.sublist?.map(l => (
                    <SubprivacyList title={l.title} desc={l.desc}/>
                  ))}
                </ul>

                <PrivacyList desc={list.footerDesc} />

                <ul className="ml-6 list-disc space-y-2 flex flex-col leading-6">
                  {list.footerSublist?.map(l => (
                    <SubprivacyList desc={l}/>
                  ))}
                </ul>
              </section>
            ))}
        </section>

        {/* <section className="space-y-6">
            <h2 className="text-2xl font-bold text-center">เงื่อนไขการใช้บริการแพลตฟอร์มซาเวียโก</h2>

            <ul className="flex flex-col">
                <li>1.1 ผู้สมัครสมาชิกจะต้องมีอายุไม่ต่ำกว่า 18 ปีบริบูรณ์ขึ้นไป (ซึ่งต่อไปนี้จะเรียกว่า สมาชิก หรือ ผู้ใช้บริการ)</li>
                <li>1.2 ผู้สมัครสมาชิกจะต้องเป็นผู้ที่มีความคิดและความรับผิดชอบที่ดีต่อสังคมอินเทอร์เน็ต ผู้สมัครจะไม่สร้างความเสียหาย เดือดร้อนหรือรำคาญแก่ผู้อื่น เช่น การส่งอีเมลโฆษณาเว็บไซต์ของตนไปยังผู้ที่ไม่ได้บอกรับอีเมล หรือ ทำการโฆษณาเว็บไซต์ของตนด้วยรูปแบบที่สร้างความเสียหายเดือดร้อนหรือสร้างความรำคาญให้แก่ผู้อื่น</li>
                <li>1.3 สมาชิกจะต้องให้ข้อมูลของสมาชิกที่ถูกต้อง และจะพยายามในการแก้ไขข้อมูลของสมาชิกให้เป็นข้อมูลที่เป็นปัจจุบัน รวมทั้งมีอีเมล สำหรับการรับข้อมูล ข่าวสารต่างๆ ที่เกี่ยวข้องกับบริการซึ่งจะแก้ไขให้เป็นปัจจุบันอยู่เสมอ และหากทางสมาชิกพบว่ามีข้อมูลไม่ถูกต้อง สมาชิกมีหน้าที่ต้องแจ้งให้บริษัททราบและทำการแก้ไขทันที</li>
            </ul>
        </section> */}
    </main>
  )
}

export default PrivacyPolicyPage