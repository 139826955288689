import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useUser } from "../../hooks/useUser";
import { useMutation, useQuery } from "react-query";
import { site } from "../../client/api";
import SetupBusiness from "./setUpBusiness";
import { useTranslation } from "react-i18next";
import DashboardVideo from "./dashboardVideo";
import { textGradient, todayDate } from "src/lib/utils";
import DashboardRecommend from "./DashboardRecommend";
import DashboardCategories from "./DashboardCategories";
import { MainModal, MainModalHeader } from "src/components/modals/MainModal";
import { Button } from "src/components/ui/button";
import { Icons } from "src/components/ui/icons";
import { usePostHog } from "posthog-js/react";
import DashboardBanner from "./dashboardBanner";
import DashboardTabs from "./DashboardTabs";

export default function Dashboard() {
  const { t, i18n } = useTranslation();

  // This value will be dynamically changed to false after users have already clicked 'get started'
  const [firstTime, setFirstTime] = useState(false);
  const [finishedSetup, setFinishedSetup] = useState(false);

  const { user, auth, logout } = useUser();
  const posthog = usePostHog();
  const { data: sites, is } = useQuery('sites', site.list, {
    enabled: !!user,
  });

  const navigate = useNavigate();

  useEffect(() => {
    console.log({
      "site_created === false": auth?.onboarding.site_created === false,
      "sites": sites,
      "find site": sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN)),
      "condition": sites && !sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN)),
    });

    if (auth?.onboarding.site_created === false || (sites?.site_list?.length && !sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN)))) {
      navigate('/dashboard/instance-configuration', { replace: true });
    }

    if (!user?.creation) return;

    const today = new Date();
    const creationDate = new Date(user.creation);
    const diffTime = Math.abs(today - creationDate);

    // if difference is more than 5 minutes, then the setup is finished
    if (auth?.onboarding.site_created && diffTime < 300000 && !localStorage.getItem('firstTime')) {
      setFirstTime(true);
      localStorage.setItem('firstTime', 'true');
    }
  }, [auth?.onboarding.site_created, user, sites]);


  const { data: loadAdmin, refetch } = useQuery('loadAdmin', () => site.login(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name, 'Admin'), {
    enabled: false,
    onSuccess: (res) => {
      //console.log(res);
    },
  });

  useEffect(() => {
    if (user && sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name && !loadAdmin) {
      refetch();
    }
  }, [user, sites, refetch]);

  // useEffect(() => {
  //   if (sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name) {
  //     loginAsAdmin({ name: sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name, reason: "Login as admin" })
  //   }
  // }, [sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name]);


  // const { data: siteOverview } = useQuery(['site', `${sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name}`], () => site.overview(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), {
  //   enabled: !!sites?.site_list.length,
  //   onSuccess: (res) => {
  //     if(res?.domains[0]?.name){
  //       loginAsAdmin({ name: res?.domains[0]?.name, reason: "Login as admin" })
  //     }
  //   }
  // });

  // const { mutate: loginAsAdmin } = useMutation('loginAsAdmin', ({ name, reason }) => site.login(name, reason), {
  //   onSuccess: (res) => {
  //     const { sid, site } = res.data.message;
  //     if (sid && site) {
  //       setwebsiteSid(sid);
  //     }
  //   }
  // });

  const OpenPage = (page, app = "app") => {
    var sid = loadAdmin?.data?.message.sid;
    var sitetoview = sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name;
    if (sid) {
      window.open(`https://${sitetoview}/${app}/${page}?sid=${sid}&posthog_session_id=${posthog.get_session_id()}`, '_blank');
    }
  }

  return (
    <div className="flex flex-col gap-y-12 main-container">
      {/* <div className="flex flex-col items-center gap-3 mt-4">
        <h2 className="text-sm text-[#B0B0B0] font-medium">{t("What do you want to do today?")}</h2>
        <h1 className="text-4xl text-[#8A8A8D] font-semibold">{todayDate(i18n.language)}</h1>
      </div> */}
      <DashboardBanner />
      <MainModal open={firstTime} onClose={setFirstTime} mainClassName="h-3/4 flex">
        <MainModalHeader />
        <section className="w-[400px] flex flex-col items-center gap-y-16 text-center mx-auto justify-center">
          <Icons.mainIcon className="w-[72px] h-[72px]" />
          <div className="flex flex-col gap-y-6">
            <h1 className="text-4xl font-bold">Say hello! to your <span className="block" style={textGradient("linear-gradient(90deg, #6600E9 25.6%, #A600E0 72.12%)")}>WorkSpace</span></h1>
            <p className="text-sm text-secondary-foreground">This is your new WorkSpace, an all in one space for all the tasks you have, from managing business work tasks.</p>
          </div>
          <Button onClick={() => setFirstTime(false)} className="regis-btn mt-8">
            Get Started
          </Button>
        </section>
      </MainModal>

      <DashboardTabs />
      {finishedSetup ? null : <SetupBusiness OpenPage={OpenPage} />}
      <section className="flex flex-col gap-y-[92px]">
        <DashboardRecommend finishedSetup={finishedSetup} OpenPage={OpenPage} />
        <DashboardVideo />
      </section>
      <DashboardCategories />
    </div>
  )
}
