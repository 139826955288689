export default function ZaviagoIcon({width='19', height='20', viewBox="0 0 19 20"}){
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} fill="none">
      <g clip-path="url(#clip0_293_15547)">
        <path d="M7.41372 0.564946L2.1099 3.56081C0.888978 4.24977 0.136719 5.52426 0.136719 6.90288V12.9256C0.136719 14.3043 0.888978 15.5787 2.1099 16.2677L7.44046 19.2787C8.66138 19.9684 10.1652 19.9684 11.3854 19.2787L14.7041 17.4049L7.44468 13.2608C6.2329 12.5691 5.48768 11.3001 5.48768 9.92702V3.89805C5.48768 2.43115 6.2885 1.23598 7.41583 0.580808C7.44609 0.562877 7.43905 0.550463 7.41372 0.564946Z" fill="url(#paint0_linear_293_15547)"/>
        <path d="M18.7361 6.86957L18.7389 12.8565C18.7389 12.8854 18.7234 12.8847 18.7234 12.8503C18.7059 11.5689 15.048 9.25164 13.7483 8.51854C13.7483 8.51854 13.1747 7.94957 12.7954 7.61923C12.4161 7.28819 12.3979 7.27302 12.115 7.08957C11.8694 6.93095 11.6139 6.80681 11.45 6.72681L11.4345 6.71922C11.2712 6.63991 10.7814 6.42819 10.7814 6.42819C9.09326 5.84681 8.60348 5.79371 7.62393 6.5344C7.62393 6.5344 6.48041 7.61923 6.04482 8.59785C5.60922 9.57647 5.49874 10.4365 5.69085 10.9785C5.7007 11.0054 5.70704 11.031 5.71196 11.0551C6.27844 12.4592 6.67252 12.682 7.66263 13.2413C7.73581 13.2834 7.81322 13.3268 7.89415 13.373L10.3986 14.5372L15.0804 16.2034L11.2487 19.2785C10.0285 19.9682 8.52396 19.9682 7.30374 19.2785L1.97248 16.2675C0.752259 15.5785 0 14.3041 0 12.9254V6.90267C0 5.52405 0.752259 4.24957 1.97248 3.5606L7.2763 0.564742C7.30233 0.550259 7.30937 0.562673 7.27911 0.580604C7.21789 0.615776 7.15667 0.666121 7.09544 0.729569L7.47474 0.515776C8.69637 -0.1725 10.2016 -0.17181 11.4232 0.517156L16.7601 3.52888C17.9817 4.21785 18.7354 5.49164 18.7361 6.86957Z" fill="url(#paint1_linear_293_15547)"/>
        <path d="M2.21414 16.4179L7.56229 19.4482C8.79518 20.14 10.3525 20.1262 11.5868 19.4331L16.975 16.4062C18.2093 15.7124 18.9693 14.4324 18.97 13.0469L18.9728 6.99721C18.9736 5.61238 18.2143 4.33238 16.9814 3.64066L13.6289 1.75928L13.586 10.0193C13.579 11.3979 12.8197 12.6703 11.591 13.36L6.1964 16.391C4.88329 17.1282 3.41044 17.0476 2.25496 16.4179C2.22329 16.4007 2.18811 16.4034 2.21414 16.4179Z" fill="url(#paint2_linear_293_15547)"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_293_15547" x1="7.38417" y1="0.558594" x2="7.51055" y2="19.7956" gradientUnits="userSpaceOnUse">
          <stop stop-color="#AD7EE1"/>
          <stop offset="1" stop-color="#2A64BB"/>
        </linearGradient>
        <linearGradient id="paint1_linear_293_15547" x1="1.37586e-07" y1="3.81921" x2="19.2879" y2="15.0323" gradientUnits="userSpaceOnUse">
          <stop stop-color="#2F99FF"/>
          <stop offset="1" stop-color="#002C7D"/>
        </linearGradient>
        <linearGradient id="paint2_linear_293_15547" x1="4.85788" y1="19.96" x2="17.6375" y2="2.73684" gradientUnits="userSpaceOnUse">
          <stop stop-color="#24A5FA"/>
          <stop offset="1" stop-color="#24A5F9"/>
        </linearGradient>
        <clipPath id="clip0_293_15547">
          <rect width="19" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}