import { Link } from "react-router-dom"

const WorkspaceCard = ({ imageUrl, title, desc, className, link, imagePadding }) => {
  return (
    <Link to={link}>
      <div className={`${className} relative`}>
        <div className="flex flex-col gap-y-1.5 p-8 text-left h-full justify-start z-5 leading-5">
          <h2 className="font-bold text-lg text-darkergray-100">{title}</h2>
          <p className="text-muted-foreground text-sm">{desc}</p>
        </div>

        <img src={imageUrl} alt={title} className="absolute right-0 h-2/3" style={{padding: imagePadding}}/>
      </div>
    </Link>
  )
}

export default WorkspaceCard