import { useTranslation } from "react-i18next"
import FeatureCard from "src/components/FeatureCard"
import video1 from "src/img/video1.svg"
import video2 from "src/img/video2.svg"
import video3 from "src/img/video3.svg"
import video4 from "src/img/video4.svg"
import hdWatch from "src/img/hd-watch.svg"
import communicateLikePro from "src/img/communicate-like-pro.svg"
import yourItem from "src/img/your-item.svg"
import yourCustomer from "src/img/your-customers.svg"
import webstudio from "src/img/webstudio.svg"
import workspace from "src/img/workspace.svg"
import { Package, UserSquare } from "@untitled-ui/icons-react/build/cjs"
import workspaceVideo from "src/img/workspace-video.mp4"
import { Icons } from "src/components/ui/icons"
import { useMediaCache } from "src/hooks/useMediaCache"
import { useEffect } from "react"

export default function DashboardRecommend({ finishedSetup, OpenPage }){

  const imagesList = [ video1, video2, video3, video4, hdWatch, communicateLikePro ]
  const { images, cacheImages } = useMediaCache()

  useEffect(() => {
    cacheImages(imagesList)
  }, [cacheImages, imagesList])

  const { t } = useTranslation()
  const videoList = {
    yourItemList: {
      title: "จัดการสินค้าและไอเท็มต่างๆในระบบ",
      desc: "การจัดการสินค้า",
      category: "Items",
      icon: yourItem,
      image: images[imagesList[0]]?.src,
      videoSrc: "",
      link: () => OpenPage("item"),
      type: "app",
      videoName: "สินค้าของคุณ",
      background: "linear-gradient(113.67deg, #1B17ED 0.77%, #007AFF 43.11%, #004999 99.23%)",
      isCover: false,
      topIcon: <Package viewBox="0 0 24 24" width="40" height="40" className="rotate-[-20deg]"/>,
      footerBgColor: "#DEEEFF1C"
    },
    yourCustomerList: {
      title: "รายชื่อลูกค้าของธุรกิจ",
      desc: "ระบบ CRM ช่วยจัดการลูกค้า",
      category: "Customers",
      icon: yourCustomer,
      image: images[imagesList[1]]?.src,
      videoSrc: "",
      link: () => OpenPage("customer"),
      type: "app",
      videoName: "ลูกค้าของคุณ",
      background: "linear-gradient(113.67deg, #ED178A 0.77%, #FF0000 43.11%, #990025 99.23%)",
      isCover: false,
      topIcon: <UserSquare viewBox="0 0 24 24" width="40" height="40"/>,
      footerBgColor: "#FFF0DE1C"
    },
    openNewSite: {
      title: "Open your new website with drag and drop",
      desc: "เปิดและจัดการเว็บไซต์",
      category: "Website",
      icon: webstudio,
      image: images[imagesList[2]]?.src,
      videoSrc: "https://procreate-assets-cdn.procreate.com/assets/hero_1_1.Clug98mv.mp4",
      link: () => {},
      videoName: "WebStudio",
      isCover: true,
      footerBgColor: "#0021441C"
    },
    yourWorkSystem: {
      title: "WorkSpace",
      desc: "ระบบการทำงานทั้งหมดของคุณ",
      category: "Work Platform",
      icon: workspace,
      image: images[imagesList[3]]?.src,
      videoSrc: workspaceVideo,
      link: () => {},
      videoName: "WorkSpace",
      loop: false,
      isCover: true,
      footerBgColor: "#0021441C"
    }
  }

  const { yourItemList, yourCustomerList, openNewSite, yourWorkSystem } = videoList
  const videosForUnfinishedSetup = [yourItemList, yourCustomerList, openNewSite, yourWorkSystem]
  const recommendedVideosForFinishedSetup = [openNewSite, yourWorkSystem]

  const videosForFinishedSetup = [
    yourItemList,
    {
      image: images[imagesList[4]]?.src,
      videoSrc: "",
      link: "",
      type: "video",
      loop: false,
      isCover: true,
      footerChildren: (
        <div className="flex items-center gap-3 text-white text-base font-medium">
          <Icons.hd />
          <p>watch the video</p>
        </div>
      )
    },
    yourCustomerList,
    {
      image: images[imagesList[5]]?.src,
      videoSrc: "",
      background: "linear-gradient(107.68deg, #0E114B 0.45%, #623991 99.55%)",
      link: "",
      type: "video",
      loop: false,
      isCover: true,
      footerChildren: (
        <div className="text-white font-medium pb-3">
          <p className="text-xs">THE DAILY LIST</p>
          <h2 className="text-2xl">Communicate like a pro</h2>
        </div>
      )
    }
  ]

  return (
    <section className="flex flex-col gap-y-5">
      {finishedSetup ? (
        <>
          <div className="flex justify-between items-center leading-7">
            <h2 className="text-darkergray-100 text-xl font-bold">{t("System suggestions")}</h2>
            <a className="text-link text-base font-medium cursor-pointer">{t("See all")}</a>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-8">
            {videosForFinishedSetup.map(video => (
              <div className={!video.hasFooter ? "relative bottom-0" : ""} key={video.videoName}>
                <FeatureCard 
                  featureName={video.videoName} 
                  desc={video.desc} 
                  title={video.title} 
                  category={video.category} 
                  icon={video.icon} 
                  link={video.link}
                  image={video.image} 
                  type={video.type} 
                  loop={video.loop}
                  videoSrc={video.videoSrc}
                  background={video.background} 
                  bgPadding={video.bgPadding} 
                  topIcon={video.topIcon}
                  isCover={video.isCover}
                  footerBgColor={video.footerBgColor}
                  hasFooter={video.hasFooter}
                  footerChildren={video.footerChildren}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-between items-center leading-7 mt-[72px]">
            <h2 className="text-darkergray-100 text-xl font-bold">{t("System suggestions")}</h2>
            <a className="text-link text-base font-medium cursor-pointer">{t("See all")}</a>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-8">
            {recommendedVideosForFinishedSetup.map(video => (
              <div className={!video.hasFooter ? "relative bottom-0" : ""} key={video.videoName}>
                <FeatureCard 
                  featureName={video.videoName} 
                  desc={video.desc} 
                  title={video.title} 
                  category={video.category} 
                  icon={video.icon} 
                  link={video.link}
                  image={video.image} 
                  type="app" 
                  loop={video.loop}
                  videoSrc={video.videoSrc}
                  background={video.background} 
                  bgPadding={video.bgPadding} 
                  topIcon={video.topIcon}
                  isCover={video.isCover}
                  footerBgColor={video.footerBgColor}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between items-center leading-7">
            <h2 className="text-darkergray-100 text-xl font-bold">{t("System suggestions")}</h2>
            <a className="text-link text-base font-medium cursor-pointer">{t("See all")}</a>
          </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-5 gap-y-8">
            {videosForUnfinishedSetup.map(video => (
              <FeatureCard 
                key={video.videoName}
                featureName={video.videoName} 
                desc={video.desc} 
                title={video.title} 
                category={video.category} 
                icon={video.icon} 
                link={video.link}
                image={video.image} 
                type="app" 
                loop={video.loop}
                videoSrc={video.videoSrc}
                background={video.background} 
                bgPadding={video.bgPadding} 
                topIcon={video.topIcon}
                isCover={video.isCover}
                footerBgColor={video.footerBgColor}
              />
            ))}
          </div>
        </>
      )}
    </section>
  )
}