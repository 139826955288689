import { useEffect, useState } from "react"

export function useTimer(seconds, timedUp){
  const [timerCount, setTimerCount] = useState(seconds)

  const restartTimer = () => {
    setTimerCount(seconds)
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds !== null && timerCount > 0) setTimerCount(timerCount - 1)
      if (timerCount == 0) timedUp()
    }, 1000)

    return () => clearInterval(timer)
  }, [timerCount])

  return {
    timer: `00:${timerCount >= 10 ? timerCount : '0' + timerCount}`,
    restartTimer
  }
}