import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { Users, Zap, UserCircle, LayoutGrid, ClipboardList, Package, Group, Baseline, Clipboard, CheckCircle2, UserSquare, Mailbox, Milestone, PackagePlus, ClipboardPaste, Unplug, ChevronDown, Search, Home, ServerCrash, Store, Crown, PanelLeftClose, PanelLeftOpen, LogIn } from "lucide-react";
import { Button } from "../ui/button";
import { LightningBoltIcon } from "@radix-ui/react-icons";
import { useMutation, useQuery } from "react-query";
import { site } from "../../client/api";
import { useTranslation } from "react-i18next";
import { workspaceImages } from "../icon-menus/workspace-images";
import { getToken, setLoginSource } from "../../utils/helper";
import { Icons } from "../ui/icons";
import { usePostHog } from "posthog-js/react";
import useModal from "src/hooks/useModal";
import { Skeleton } from "../ui/skeleton";
import useDashboardActivities from "src/hooks/useDashboardActivities";
import { SearchBar } from "../topbar/searchBar";
import AppsListModal from "../topbar/components/AppsListModal";
import { cn } from "src/lib/utils";

// import TeamModal from "../components/switchTeamModal";

export default function Sidebar({ isSidebarOpen, setIsSidebarOpen }) {
  const { t } = useTranslation()
  const [active, setActive] = useState('');
  const location = useLocation();
  const [upgraded, setUpgraded] = useState(false)
  const sso_token = getToken();
  const { dashboardTriggers } = useDashboardActivities()
  const posthog = usePostHog();
  const benchApps = useQuery('benchApps', () => site.appslist(sites.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), { enabled: false });
  const installedApps = useQuery('installed_apps', () => site.installed_apps(sites.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name), { enabled: false });
  const appslists = benchApps.data || [];
  const ToggleIcon = isSidebarOpen ? PanelLeftClose : PanelLeftOpen
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [hideSidebar, setHideSidebar] = useState("")

  const navigate = useNavigate();

  const { openModal } = useModal();
  const { data: sites } = useQuery('sites', site.list, { enabled: false });
  const { mutate: loginAsAdmin } = useMutation('loginAsAdmin', ({ name, reason }) => site.login(name, reason), {
    onSuccess: (res) => {
      const { sid, site } = res.data.message;
      if (sid && site) {
        window.open(`https://${site}/app/home?sid=${sid}&posthog_session_id=${posthog.get_session_id()}`, '_blank');
      }
    }
  });

  const OpenPage = (page, app = "app") => {
    // var sid = loadAdmin?.data?.message.sid;
    var sitetoview = sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name;
    window.open(`https://${sitetoview}/${app}/${page}`, '_blank');
  }

  const navigation = [
    {
      icon: <Search className="h-5 w-5"/>,
      title:"Search",
      onClick: () => setIsSearchOpen(true)
    },
    {
      icon: <Home className="h-5 w-5"/>,
      title:"Home",
      onClick: () => navigate('/dashboard/app'),
      current: active === "/dashboard/app"
    },
    {
      icon: <Users className="h-5 w-5"/>,
      title:"Teams",
      onClick: () => openModal('/teams/member'),
    },
    {
      icon: <ServerCrash className="h-5 w-5"/>,
      title:"Usage",
      onClick: () => openModal('/billing/usages'),
    },
    {
      icon: <Icons.chat />,
      title:"Chat",
      onClick: () => window.location.href = "https://huly.zaviago.com/"
    },
    {
      icon: <Store className="h-5 w-5"/>,
      title:"My Storefront",
      onClick: () => OpenPage("", "store")
    }
  ]

  const settingsMenus = [
    {
      name: 'Inventory', icon: <LightningBoltIcon />, submenus: [
        { title: 'Items', icon: <Package className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Item Group', icon: <Group className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Brand', icon: <Baseline className="w-4 h-4 stroke-[1.5] text-darkergray" /> }
      ]
    },
    {
      name: 'Orders', icon: <ClipboardList className="w-4 h-4 stroke-[1.5] text-darkergray" />, submenus: [
        { title: 'Sales Invoice', icon: <Clipboard className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Payment Entry', icon: <CheckCircle2 className="w-4 h-4 stroke-[1.5] text-darkergray" /> }
      ]
    },
    {
      name: 'Customers', icon: <UserCircle className="w-4 h-4 stroke-[1.5] text-darkergray" />, submenus: [
        { title: 'Customer', icon: <UserSquare className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Customers Group', icon: <Users className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Contact', icon: <Mailbox className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Address', icon: <Milestone className="w-4 h-4 stroke-[1.5] text-darkergray" /> }
      ]
    },
    {
      name: 'Stock', icon: <LayoutGrid className="w-4 h-4 stroke-[1.5] text-darkergray" />, submenus: [
        { title: 'Stock Entry', icon: <PackagePlus className="w-4 h-4 stroke-[1.5] text-darkergray" /> },
        { title: 'Delivery Note', icon: <ClipboardPaste className="w-4 h-4 stroke-[1.5] text-darkergray" /> }
      ]
    },
  ]

  const yourSites = [
    { name: t('menus.integration'), icon: <Zap className="w-4 h-4 stroke-[1.5] text-darkergray" />, id: 'integration', href: '/integration/manage-apps', current: active === '/integration/manage-apps' || active === '/integration/upgrade-apps' ? true : false },
    { name: 'App Store', icon: <Unplug className="w-4 h-4 stroke-[1.5] text-darkergray" />, id: 'app-store', href: '/integration/appstore', current: active === '/integration/appstore' ? true : false },
  ]

  const workspaceApp = [
    { name: 'Workspace', icon: workspaceImages.customerDataSystem, onClick: () => navigate('/dashboard/workspace') },
    { name: 'TeamInbox', icon: workspaceImages.pos, onClick: () => navigate('/dashboard/workspace/inbox') },
    { name: 'WorkDay', icon: workspaceImages.projectManagement, onClick: () => navigate('/dashboard/workspace/workday') },
    { name: 'LineCRM', icon: workspaceImages.linecrm, onClick: () => navigate('/dashboard/workspace/line-crm') },
    { name: 'SalesTeam', icon: workspaceImages.salesteam, onClick: () => window.open('/coming-soon', '_blank') },
    { name: 'Zaviago HR', icon: workspaceImages.hrspace, onClick: () => window.open('https://apps.hosting.zaviago.com/app/hr', '_self') },
  ]

  const workspaceAppTwo = [
    { name: 'Data Insight', icon: workspaceImages.blogAndNews, onClick: () => navigate('/dashboard/workspace/data-insight') },
    { name: 'Drive', icon: workspaceImages.manageWebsite, onClick: () => navigate('/dashboard/workspace/drive') },
  ]

  const workspaceAppThree = [
    { name: 'Blogs', icon: workspaceImages.blogAndNews, onClick: () => window.open('https://ghost.zaviago.com/ghost/#/dashboard', '_self') },
    {
      name: 'Builder',
      icon: workspaceImages.manageWebsite,
      onClick: () => {
        const team = localStorage.getItem('current_team');
        let url = `https://site-builder.zaviago.com/login?token=${sso_token}`;

        if (team !== null && team !== undefined) {
          url += `&team=${team}`;
        }
        window.location.href = url;
      }
    },
    { name: 'Whiteboard', icon: workspaceImages.whiteboard, onClick: () => window.open('https://affine.zaviago.com/', '_self') },
  ]

  useEffect(() => {
    setActive(location.pathname);
    if (isSidebarOpen){
      setHideSidebar("")
    } else {
      setTimeout(() => setHideSidebar("hidden"), 300)
    }
  }, [isSidebarOpen, hideSidebar])

  const [query, setQuery] = useState('')
  const [sellingMenus, setSellingMenus] = useState(false)
  const [activeSubmenus, setActiveSubmenus] = useState([]);

  const handleSubMenuClick = (index) => {
    setActiveSubmenus((prev) => {
      const newActiveSubmenus = [...prev];
      newActiveSubmenus[index] = !newActiveSubmenus[index];
      return newActiveSubmenus;
    });
  };

  const { data: loadAdmin, refetch } = useQuery('loadAdmin', () => site.login(sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name, 'Admin'), {
    enabled: false,
    onSuccess: (res) => {
      //console.log(res);
    },
  });

  const loginNow = (page) => {
    var sid = loadAdmin?.data?.message.sid;
    var sitetoview = sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name;
    setLoginSource(sitetoview);
    if (sid) {
      window.open(`https://${sitetoview}/app/${page}?sid=${sid}&posthog_session_id=${posthog.get_session_id()}`, '_blank');
    }
  }

  const squareGradient = {
    background: "linear-gradient(248.71deg, rgba(251, 152, 106, 0.16) 9.18%, rgba(251, 152, 106, 0.16) 32.04%, rgba(244, 99, 173, 0.16) 50.41%, rgba(34, 85, 207, 0.16) 90.82%)"
  }

  return (
    <>
      <div className={cn("w-screen h-screen fixed z-50 bg-transparent lg:hidden top-0 left-0 transition duration-200", "invisible opacity-0", {"visible opacity-1": isSidebarOpen})} onClick={() => setIsSidebarOpen(false)}/>
      <div id="sidebar" className={hideSidebar}>
        <ToggleIcon className='text-[#424149DB] cursor-pointer -ml-[3px] lg:hidden min-h-[24px]' onClick={() => setIsSidebarOpen(!isSidebarOpen)}/>
        <div className="flex items-center gap-x-2 relative cursor-pointer col-span-1" onClick={() => openModal()}>
          <Icons.mainIcon className="rotate-180 min-h-5 min-w-[20px]" />
          <p className={`text-xs text-darkergray-800 font-semibold flex items-center gap-2 sf-pro tracking-[-0.43px] lg:tracking-[0]`}>
            {sites ? <span className="max-w-[168px] overflow-hidden text-ellipsis">{sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name}</span> : <Skeleton className='h-3 w-40 rounded-sm'/>}
            <ChevronDown className="w-4 h-4" />
          </p>
        </div>

        <div className="flex flex-col gap-2.5">
          <AppsListModal trigger={
            <Button className="!bg-link text-sm text-white h-11 rounded-[14px] w-full shadow-none gap-2 font-semibold">
              <LogIn />
              Go to Application
            </Button>
          }/>
          <Button className="!bg-white text-sm text-black h-11 rounded-[14px] w-full shadow-none gap-2 font-semibold">
            <Crown className="text-[#FBBD43]"/>
            Try Pro for 14 days
          </Button>
        </div>

        <div className="flex flex-1 flex-col w-full h-full overflow-auto pb-[26px]">
          <nav className="flex justify-center flex-col gap-y-3" aria-label="Sidebar">
            <section className="flex flex-col gap-y-2">
              {navigation.map((item, index) => (
                <Button key={index} variant='ghost' onClick={item.onClick} className={`text-[#424149] rounded-xl text-[16px] h-11 w-full flex gap-[14px] items-center justify-start hover:bg-lightgray-600/60 ${item.current ? 'bg-lightgray-600/60' : ''}`}>
                  {item.icon}
                  {item.title}
                </Button>
              ))}
            </section>

            <section className="flex flex-col gap-y-2 pt-3">
              <h3 className="text-[14px] text-[#858585] mb-5">Quickview</h3>
              {dashboardTriggers.slice(0, 2).map((item, index) => (
                <Button key={index} variant='ghost' onClick={item.onClick} className={`text-[#424149] rounded-xl h-11 w-full flex gap-2 items-center justify-start hover:bg-lightgray-600/60 ${item.current ? 'bg-lightgray-600/60' : ''}`}>
                  <img src={item.icon} className="h-7 w-7" alt={item.title}/>
                  {item.title}
                </Button>
              ))}
            </section>
          </nav>
          {/* {!upgraded && <SidebarUpgrade onClick={() => setIsSidebarOpen(false)} />} */}
        </div>
      </div>

      <SearchBar isOpen={isSearchOpen} setIsOpen={setIsSearchOpen}/>
    </>
  )
}