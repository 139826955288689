export default function AdvantageCard({ onClick, icon, title, desc }){
    return (
      <div className="cursor-pointer" onClick={onClick}>
        <div className={`h-full bg-[#FAFAFC] rounded-[15px] p-6 flex flex-col gap-y-3`}>
          <div className="bg-white p-2 rounded-lg shadow-icon w-fit">{icon}</div>
          <div className="flex flex-col leading-5 gap-1">
            <h2 className="text-darkergray-300 text-base font-medium">{title}</h2>
            <p className="text-lightgray-400 text-xs">{desc}</p>
          </div>
        </div>
      </div>
    )
}