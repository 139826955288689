import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar"
import { useState } from 'react'
import { useUser } from "src/hooks/useUser";
import { useTranslation } from "react-i18next";
import { AccountModalButton, AccountModalMenus } from "src/components/topbar/components/AccountModalMenus";
import { Icons } from "src/components/ui/icons";
import { SearchMd } from "@untitled-ui/icons-react/build/cjs";
import { textGradient } from "src/lib/utils";
import TeamMemberIcon from "src/components/icon-menus/TeamMemberIcon";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { Command, CommandGroup, CommandItem, CommandList } from "src/components/ui/command";
import { Dialog, DialogContent, DialogTitle, DialogDescription, DialogFooter, DialogClose, DialogHeader, DialogOverlay } from "src/components/ui/dialog";

export default function TeamMembers(){
  const { auth } = useUser();
  const { t } = useTranslation()

  const [checkedFilter, setCheckedFilter] = useState([])
  const [search, setSearch] = useState('')
  const [removeMember, setRemoveMember] = useState(false)

  const handleCheckFilter = (option) => {
    setCheckedFilter((prevFilters) => {
      if (prevFilters.includes(option)) {
        return prevFilters.filter((filter) => filter !== option);
      } else {
        return [...prevFilters, option];
      }
    });
  }

  const allMembers = auth?.team_members.filter(name => name.first_name.toUpperCase().includes(search.toUpperCase()) || name.last_name.toUpperCase().includes(search.toUpperCase()))
  // const filteredMembers = allMembers.filter((member) =>
  //   checkedFilter.length === 0 || checkedFilter.includes(member.roles[0])
  // );

  return (
    <>
      {/* <div className="mt-[10px] flex flex-col md:flex-row gap-2"> */}
        {/* <div className="flex items-center gap-x-2">
          <Popover>
            <PopoverTrigger>
              <Button variant='outline' className='border border-dashed flex items-center gap-x-2'>
                <div className="flex items-center gap-x-2">
                  <PlusCircle viewBox='0 0 24 24' width='16' height='16'/>
                  {t('teams.role')}
                </div>
                {checkedFilter.length > 0 && (
                  <>
                    <DrawLine color='#E4E4E7' height="80%" width="1px"/>
                    {checkedFilter.length < 3 ? (
                      <>
                        {checkedFilter.map(c => (
                          <Badge variant="secondary" className='rounded-md'>{c}</Badge>
                        ))}
                      </>
                    ) : (
                      <Badge variant="secondary" className='rounded-md'>{checkedFilter.length} selected</Badge>
                    )}
                  </>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className='w-[200px] p-0 fixed -left-[54px]'>
              <div className="p-1">
                {userRoles.map(option => (
                  <div className="flex items-center justify-between px-2 py-[6px]">
                    <div className="flex items-center gap-x-2">
                      <Checkbox id={`filter-${option.role}`} checked={checkedFilter.includes(option.role)} onCheckedChange={() => handleCheckFilter(option.role)}/>
                      <label htmlFor={`filter-${option.role}`} className="subheading">{option.role}</label>
                    </div>
                  </div>
                ))}
              </div>

              {checkedFilter.length > 0 && (
                <>
                  <DrawLine width='100%' height='1px' color='#E4E4E7'/>
                  <div className="p-1">
                    <Button className='w-full font-normal' variant='ghost' onClick={() => setCheckedFilter([])}>{t('teams.clear_filters')}</Button>
                  </div>
                </>
              )}
            </PopoverContent>
          </Popover>
          <Button variant='ghost' className={`flex items-center gap-x-2 ${search !== '' || checkedFilter.length > 0 ? 'visible opacity-1' : 'invisible opacity-0'} transition duration-200`} onClick={() => {setSearch('');setCheckedFilter([])}}> 
            {t('reset')}
            <X viewBox="0 0 24 24" width='16' height='16'/>
          </Button>
        </div>
      </div> */}

      {auth?.team_members?.length > 0 ? (
        <div className="flex flex-col items-center gap-10">
          <Icons.teamPageIcon />
          <div className='w-[358px] shadow-team-members bg-white h-11 flex rounded-lg items-center px-3'>
            <SearchMd className="h-5 w-5 text-lightgray-900/60"/>
            <Input className="bg-white !outline-none !ring-0 !ring-offset-0 !text-base placeholder:text-center" placeholder={t("Search or enter member name")} value={search} onChange={(e) => setSearch(e.target.value)}/>
          </div>

          <section className="flex flex-col gap-3 w-full relative">
            {allMembers?.length > 0 ? allMembers?.map((member, index) => (
              <Popover portal modal={true}>
                <PopoverTrigger className="w-full">
                  <AccountModalMenus>
                    <AccountModalButton 
                      className="p-4"
                      title={`${member.first_name} ${member.last_name}`}
                      desc={member.roles[0]}
                      icon={(
                        <Avatar>
                          <AvatarImage src={member.avatar} />
                          <AvatarFallback>{member.first_name[0]}{member.last_name[0]}</AvatarFallback>
                        </Avatar>
                      )}
                    />
                  </AccountModalMenus>
                </PopoverTrigger>
                <PopoverContent className={`p-0 ${removeMember ? "hidden" : "!z-99"}`} align="end">
                  <Command>
                    <CommandList>
                      <CommandGroup className="p-1.5">
                        <CommandItem className="flex items-start gap-x-4">
                          Admin
                        </CommandItem>
                        <CommandItem className="flex items-start gap-x-4">
                          Member
                        </CommandItem>
                        {allMembers?.length > 1 ? (
                          <CommandItem onSelect={() => setRemoveMember(true)}>
                            Remove
                          </CommandItem>
                        ) : null}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            )) : <p className="text-center">{t("Team members not found")}</p>}

            <Dialog open={removeMember} onOpenChange={setRemoveMember}>
              <DialogOverlay className="!z-[100]"/>
              <DialogContent className="!z-[100]">
                <DialogHeader>
                  <DialogTitle>{t('teams.remove_team.title')}</DialogTitle>
                  <DialogDescription>
                    {t('teams.remove_team.desc')}
                  </DialogDescription>
                  <DialogFooter className="!mt-8">
                    <DialogClose>
                      <Button variant='outline'>{t('cancel')}</Button>
                    </DialogClose>
                    <Button type="submit">{t('teams.remove_team.continue')}</Button>
                  </DialogFooter>
                </DialogHeader>
              </DialogContent>
            </Dialog>
          </section>
        </div>
      ) : (
        <div className="w-[400px] flex flex-col items-center gap-y-10 text-center mx-auto justify-center">
          <TeamMemberIcon className="w-24 h-24"/>
          <div className="flex flex-col gap-y-6 sf-pro-rounded px-5">
            <h1 className="text-4xl font-bold"><span className="inline-block" style={textGradient("linear-gradient(90deg, #6600E9 25.6%, #A600E0 72.12%)")}>WorkSpace</span> Sharing and team invite</h1>
            <p className="text-sm text-secondary-foreground">Share your workspace, start chat and meeting collaboration, manage stock and create multiple team and department for your team here.</p>
          </div>
          <Button className="regis-btn">Manage Team Members</Button>
        </div>
      )}

      {/* <div className="flex flex-col gap-y-6 mt-8 mb-9 lg:my-6">
        <DataList listPerPage={6} emptyText={t("teams.no_members")}>
          {allMembers?.map(t => (
            <TeamCard firstname={t.first_name} lastname={t.last_name} role={t.roles[0]} email={t.name}/>
          )) || <Loading />}
        </DataList>
      </div> */}
    </>
  )
}