import { useQuery } from "react-query";
import { workspaceBanners, workspaceImages } from "src/components/icon-menus/workspace-images"
import { site } from "../client/api";
import { Icons } from "src/components/ui/icons";
import ParticleStar from "src/components/icon-menus/ParticleStar";
import ParticleStarTwo from "src/components/icon-menus/ParticleStarTwo";

const useWorkspaces = (type) => {

  const { data: sites } = useQuery('sites', site.list, { enabled: false });
  const checkIfSites = sites ? sites?.site_list.find(site => site.name.includes(process.env.REACT_APP_SITE_DOMAIN))?.name : ""

  const banners = type === "workday" ? {
    bannerImage: workspaceBanners.workdayImg,
    title: "workday.",
    titleColor: "#000000",
    icon: workspaceImages.manageBusiness,
    icons: [<ParticleStarTwo />],
    desc: <>The everything app for work</>,
    descColor: "#000000"
  } : type === "document" ? {
    bannerImage: workspaceBanners.documentImg,
    title: "document.",
    titleColor: "#003E81",
    icon: workspaceImages.manageBusiness,
    icons: [<ParticleStarTwo />, <Icons.sparkles />, <Icons.sparklesTwo />],
    desc: <>ทุกเอกสารของคุณจัดเก็บไว้ในจุดเดียว<br/>พร้อมเอไอช่วยเขียนงานต่างๆ</>,
    descColor: "#58799D"
  } : type === "webstudio" ? {
    bannerImage: workspaceBanners.webstudioImg,
    title: "webstudio.",
    titleColor: "#560046",
    icon: workspaceImages.manageBusiness,
    icons: [<ParticleStarTwo />, <Icons.sparkles />, <Icons.sparklesTwo />],
    desc: <>ระบบสร้างเว็บไซต์ของคุณ เปิดเว็บให้โลก<br/>รู้เกี่ยวกับคุณและงานของคุณ</>,
    descColor: "#A17098F5"
  } : type === undefined ? {
    bannerImage: workspaceBanners.workspaceImg,
    title: "workspace.",
    titleColor: "#292929",
    icon: workspaceImages.manageBusiness,
    icons: [<ParticleStarTwo />, <Icons.sparkles />],
    desc: <>จัดการทุกอย่างให้กับธุรกิจ เริ่มจากจัดการสินค้า คำสั่งซื้้อ<br/>จนถึงทุกการจัดการของการเงินและจัดส่ง</>,
    descColor: "#989898"
  } : {
    bannerImage: "",
    title: "",
    titleColor: "",
    icon: null,
    desc: "",
    descColor: ""
  }

  const website =
    type === "inbox" ? "chat.zaviago.com"
      : type === "workday" ? "workday.zaviago.com"
        : type === "drive" ? `${checkIfSites}/drive`
          : checkIfSites

  const bgGradient = type === "builder" ?
    "linear-gradient(180deg, #FFFFFF -72.08%, #FFFFFF -72.04%, #FFE3F4 224.77%)"
    : type === "drive" ?
      "linear-gradient(180deg, #FFFFFF -72.08%, #FFFFFF -72.04%, #AACCFF 224.77%)"
      : type === "data-insight" ?
        "linear-gradient(180deg, #FFFFFF -30.11%, #B6DCFF 238.76%)"
        : type === "line-crm" ?
          "linear-gradient(180deg, #FFFFFF -30.11%, #CCEFDC 238.76%)"
          : type === "workday" ?
            "linear-gradient(180deg, #FFFFFF -72.08%, #E8CCF5 238.76%)"
            : type === "inbox" ?
              "linear-gradient(180deg, #FFFFFF -72.08%, #C5E2FF 238.76%)"
              : "linear-gradient(180deg, #FFFFFF -72.08%, #FFFFFF -72.04%, #DDD5FF 224.77%)"

  return { banners, bgGradient, website }
}

export default useWorkspaces