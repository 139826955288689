import PaymentInfo from "./paymentInfo";
import PaymentForm from "./paymentForm";
import PlanPayment from "./planPayment";
import { useParams } from "react-router";
import { useState } from 'react';

export default function PaymentPage(){
  const { app } = useParams();
  const [isCompany, setIsCompany] = useState(false);
  const [deductWHT, setDeductWHT] = useState(false);

  const handleTypeChange = (isCompanyValue) => {
    setIsCompany(isCompanyValue);
  };

  const handleChangeWHTInvoice = (value) => {
    setDeductWHT(value);
  };

  let paymentComponent;
  if (app === 'plan') {
    paymentComponent = <PlanPayment isCompany={isCompany} deductWHT={deductWHT} />; 
  } else {
    paymentComponent = <PaymentInfo isCompany={isCompany}  deductWHT={deductWHT} />;
  }
 

  return (
    <div className="page-container flex flex-col md:flex-row md:gap-2.5 lg:gap-10">
      {paymentComponent}
      <PaymentForm handleTypeChangeInvoice={handleTypeChange} handleonChangeWHTInvoice={handleChangeWHTInvoice} />
    </div>
  )
}