import { useNavigate } from "react-router-dom"
import { workspaceImages } from "src/components/icon-menus/workspace-images"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/components/ui/tabs"
import ComingSoonPage from "../coming-soon"

const MainDashboard = () => {
  const navigate = useNavigate()
  const tabBtnClassName = "font-medium !rounded-lg !px-4 !py-2 text-sm !shadow-workspace bg-white leading-5 flex items-center gap-2 text-darkergray-300"
  const dashboardTriggers = [
    { title: "เอกสาร", type: "document", icon: workspaceImages.mainIcon },
    { title: "พรีเซ็นเทชั่น", type: "presentation", icon: workspaceImages.manageBusiness },
    { title: "ไวท์บอร์ด", type: "whiteboard", icon: workspaceImages.customerDataSystem },
    { title: "กราฟฟิก", type: "graphic", icon: workspaceImages.manageWebsite },
    { title: "Sheet", type: "sheet", icon: workspaceImages.projectManagement },
    { title: "ไฟล์ต่างๆ", type: "file", icon: workspaceImages.graphicDesign },
  ]

  const fileNames = [
    { image:"", title:"files name [API]", category:"Presentation" },
    { image:"", title:"files name [API]", category:"Presentation" },
    { image:"", title:"files name [API]", category:"Presentation" },
    { image:"", title:"files name [API]", category:"Presentation" },
    { image:"", title:"files name [API]", category:"Presentation" },
    { image:"", title:"files name [API]", category:"Presentation" },
    { image:"", title:"files name [API]", category:"Presentation" },
    { image:"", title:"files name [API]", category:"Presentation" },
    { image:"", title:"files name [API]", category:"Presentation" },
    { image:"", title:"files name [API]", category:"Presentation" },
    { image:"", title:"files name [API]", category:"Presentation" },
    { image:"", title:"files name [API]", category:"Presentation" },
  ]

  return (
    // This code will be used after fetched API
    // <main className="flex flex-col gap-y-12 main-container">
    //   <Tabs defaultValue="document">
    //     <TabsList className={`!bg-transparent !flex !gap-x-4 !text-black justify-center flex-nowrap h-auto`}>
    //       {dashboardTriggers.map(trigger => (
    //         <TabsTrigger key={trigger.title} value={trigger.type} className={tabBtnClassName}>
    //           <img src={trigger.icon} className="w-4 h-4"/>
    //           {trigger.title}
    //         </TabsTrigger>
    //       ))}
    //     </TabsList>
    //     {dashboardTriggers.map(trigger => (
    //       <TabsContent value={trigger.type} className="mt-[72px]">
    //         <section className="grid grid-cols-4 gap-x-6 gap-y-[30px]">
    //           {fileNames.map(file => (
    //             <div className="flex flex-col gap-4">
    //               {file.image ? <img src={file.image} className="bg-[#FAFAFC] rounded-[15px] h-[175px] w-full"/> : <div className="bg-[#FAFAFC] rounded-[15px] h-[175px] w-full"/>}
    //               <div className="flex flex-col">
    //                 <h3 className="text-sm text-darkergray font-medium">{file.title}</h3>
    //                 <p className="text-xs text-lightgray-400">{file.category}</p>
    //               </div>
    //             </div>
    //           ))}
    //         </section>
    //       </TabsContent>
    //     ))}
    //   </Tabs>
    // </main>

    // This will be removed soon
    <main className="absolute top-0 left-0">
      <ComingSoonPage />
    </main>
  )
}

export default MainDashboard