import forYou from "src/img/workspace-icons/for-you.png"
import manageBusiness from "src/img/workspace-icons/manage-business.png"
import manageWebsite from "src/img/workspace-icons/manage-website.png"
import blogAndNews from "src/img/workspace-icons/blog-and-news.png"
import customerDataSystem from "src/img/workspace-icons/customer-data-system.png"
import hrspace from "src/img/workspace-icons/hrspace.png"
import linecrm from "src/img/workspace-icons/linecrm.png"
import salesteam from "src/img/workspace-icons/salesteam.png"
import whiteboard from "src/img/workspace-icons/whiteboard.png"
import pos from "src/img/workspace-icons/pos.png"
import projectManagement from "src/img/workspace-icons/project-management.png"
import graphicDesign from "src/img/workspace-icons/graphic-design.png"
import mainIcon from "src/img/workspace-icons/main-icon.svg"
import workday from "src/img/workspace-icons/workday.png"
import document from "src/img/workspace-icons/document.png"
import presentationIcon from "src/img/workspace-icons/presentation.png"

import workspaceImg from "src/img/workspace-banner-imgs/workspace-img.png"
import webstudioImg from "src/img/workspace-banner-imgs/webstudio-img.png"
import workdayImg from "src/img/workspace-banner-imgs/workday-img.png"
import documentImg from "src/img/workspace-banner-imgs/document-img.png"

import banner from "src/img/dashboard-activities/banner.png"
import whiteboardImg from "src/img/dashboard-activities/whiteboard.png"
import createWebsites from "src/img/dashboard-activities/create-websites.png"
import createWhiteboard from "src/img/dashboard-activities/create-whiteboard.png"
import manageOA from "src/img/dashboard-activities/manage-oa.png"
import presentation from "src/img/dashboard-activities/presentation.png"
import startOnlineWebsite from "src/img/dashboard-activities/start-online-website.svg"
import customDomain from "src/img/dashboard-activities/custom-domain.svg"
import responsiveDesign from "src/img/dashboard-activities/responsive-design.svg"
import manageYourBusiness from "src/img/dashboard-activities/manage-your-business.svg"
import manageProducts from "src/img/dashboard-activities/manage-products.png"
import manageOrders from "src/img/dashboard-activities/manage-orders.png"
import otherCategories from "src/img/dashboard-activities/other-categories.png"
import checkSales from "src/img/dashboard-activities/check-sales.png"
import warehouses from "src/img/dashboard-activities/warehouses.png"
import addProducts from "src/img/dashboard-activities/add-products.png"
import addOrders from "src/img/dashboard-activities/add-orders.png"
import checkTransactions from "src/img/dashboard-activities/check-transactions.png"
import productCategories from "src/img/dashboard-activities/product-categories.png"
import loyaltyProgram from "src/img/dashboard-activities/loyalty-program.png"
import loyaltyProgramTwo from "src/img/dashboard-activities/loyalty-program-2.png"
import projectManagementImg from "src/img/dashboard-activities/project-management.png"
import customerData from "src/img/dashboard-activities/customer-data.png"
import addCustomers from "src/img/dashboard-activities/add-customers.png"
import setCustomerGroups from "src/img/dashboard-activities/set-customer-groups.png"
import allWhiteboards from "src/img/dashboard-activities/all-whiteboards.png"
import yourPages from "src/img/dashboard-activities/your-pages.png"
import newPage from "src/img/dashboard-activities/new-page.png"
import allBlogs from "src/img/dashboard-activities/all-blogs.png"
import addDiscounts from "src/img/dashboard-activities/add-discounts.png"
import bundleDeal from "src/img/dashboard-activities/bundle-deal.png"
import addOnDeal from "src/img/dashboard-activities/add-on-deal.png"
import flashSale from "src/img/dashboard-activities/flash-sale.png"
import rewards from "src/img/dashboard-activities/rewards.png"
import customerLevels from "src/img/dashboard-activities/customer-levels.png"
import pointPurchase from "src/img/dashboard-activities/point-purchase.png"
import salesAnalysis from "src/img/dashboard-activities/sales-analysis.png"
import customerAnalysis from "src/img/dashboard-activities/customer-analysis.png"
import allDashboardData from "src/img/dashboard-activities/all-dashboard-data.png"
import yourReportDoc from "src/img/dashboard-activities/your-report-documents.png"
import newDataDashboard from "src/img/dashboard-activities/new-data-dashboard.png"
import newDataQuery from "src/img/dashboard-activities/new-data-query.png"
import chatAndInboxes from "src/img/dashboard-activities/chat-and-inboxes.png"
import addManageAdmin from "src/img/dashboard-activities/add-manage-admin.png"
import manageAdminTeam from "src/img/dashboard-activities/manage-admin-team.png"
import manageTags from "src/img/dashboard-activities/manage-tags.png"
import chatReports from "src/img/dashboard-activities/chat-reports.png"
import allEmployees from "src/img/dashboard-activities/all-employees.png"
import employeeCheckinInfo from "src/img/dashboard-activities/employee-checkin-info.png"
import allApplicants from "src/img/dashboard-activities/all-applicants.png"
import employeeLeaves from "src/img/dashboard-activities/employee-leaves.png"
import uploadFiles from "src/img/dashboard-activities/upload-files.png"
import newDocument from "src/img/dashboard-activities/new-document.png"
import sharedWithMe from "src/img/dashboard-activities/shared-with-me.png"
import myDrive from "src/img/dashboard-activities/my-drive.png"
import posImg from "src/img/dashboard-activities/manage-pos.png"

import newProductWorkspace from "src/img/workspace-activities/new-product-workspace.png"
import manageOrdersWorkspace from "src/img/workspace-activities/manage-orders-workspace.png"
import addOrdersWorkspace from "src/img/workspace-activities/add-orders-workspace.png"
import workspaceBannerImg from "src/img/workspace-activities/workspace-banner.png"
import connectWorkspace from "src/img/workspace-activities/connect-workspace.png"
import realTimeData from "src/img/workspace-activities/real-time-data.png"
import yourCustomers from "src/img/workspace-activities/your-customers.png"
import allChats from "src/img/workspace-activities/all-chats.png"
import manageAdminInbox from "src/img/workspace-activities/manage-admin-inbox.png"
import otherTags from "src/img/workspace-activities/other-tags.png"
import rewardProgramme from "src/img/workspace-activities/reward-programme.png"
import pointRedemption from "src/img/workspace-activities/point-redemption.png"
import orderLineCrm from "src/img/workspace-activities/order-line-crm.png"
import openInsight from "src/img/workspace-activities/open-insight.png"
import allDashboardsWorkspace from "src/img/workspace-activities/all-dashboards-workspace.png"
import addDashboard from "src/img/workspace-activities/add-dashboard.png"
import yourStoredFiles from "src/img/workspace-activities/your-stored-files.png"
import uploadFilesWorkspace from "src/img/workspace-activities/upload-files-workspace.png"
import createDocs from "src/img/workspace-activities/create-docs.png"
import allPagesWorkspace from "src/img/workspace-activities/all-pages-workspace.png"
import createNewPage from "src/img/workspace-activities/create-new-page.png"
import createNewBlog from "src/img/workspace-activities/create-new-blog.png"
import workdayDashboard from "src/img/workspace-activities/workday-dashboard.png"
import workdayInbox from "src/img/workspace-activities/workday-inbox.png"
import workdayDocument from "src/img/workspace-activities/workday-document.png"
import workdayTeamwork from "src/img/workspace-activities/workday-teamwork.png"
import workdayTemplate from "src/img/workspace-activities/workday-template.png"
import workdaySpaces from "src/img/workspace-activities/workday-spaces.png"
import goToWorkspace from "src/img/workspace-activities/go-to-workspace.png"
import goToDashboard from "src/img/workspace-activities/go-to-dashboard.png"
import otherSystemsWorkday from "src/img/workspace-activities/other-systems-workday.png"
import otherSystemsLinecrm from "src/img/workspace-activities/other-systems-linecrm.png"
import goToDrive from "src/img/workspace-activities/go-to-drive.png"
import goToBuilder from "src/img/workspace-activities/go-to-builder.png"

import airdrop from "src/img/invite-team/airdrop.svg"
import lineMessage from "src/img/invite-team/line-message.svg"
import mail from "src/img/invite-team/mail.svg"
import qrscan from "src/img/invite-team/qr-scan.svg"
import reminders from "src/img/invite-team/reminders.svg"

export const inviteTeamImages = {
  airdrop, lineMessage, mail, qrscan, reminders
}

export const workspaceBanners = {
  workdayImg,
  documentImg,
  webstudioImg,
  workspaceImg
}

export const workspaceImages = {
  forYou, 
  manageBusiness, 
  manageWebsite,
  blogAndNews,
  customerDataSystem,
  hrspace,
  linecrm,
  salesteam,
  whiteboard,
  pos,
  projectManagement,
  graphicDesign,
  mainIcon,
  workday,
  document,
  presentationIcon
}

export const dashboardActivitiesImages = {
  banner,
  whiteboardImg,
  createWebsites,
  createWhiteboard,
  manageOA,
  presentation,
  startOnlineWebsite,
  customDomain,
  responsiveDesign,
  manageYourBusiness,
  manageProducts,
  manageOrders,
  otherCategories,
  checkSales,
  warehouses,
  addProducts,
  addOrders,
  checkTransactions,
  productCategories,
  loyaltyProgram,
  loyaltyProgramTwo,
  projectManagementImg,
  customerData,
  addCustomers,
  setCustomerGroups,
  allWhiteboards,
  yourPages,
  newPage,
  allBlogs,
  addDiscounts,
  bundleDeal,
  addOnDeal,
  flashSale,
  rewards,
  customerLevels,
  pointPurchase,
  salesAnalysis,
  customerAnalysis,
  allDashboardData,
  yourReportDoc,
  newDataDashboard,
  newDataQuery,
  chatAndInboxes,
  addManageAdmin,
  manageAdminTeam,
  manageTags,
  chatReports,
  allEmployees,
  employeeCheckinInfo,
  allApplicants,
  employeeLeaves,
  uploadFiles,
  newDocument,
  sharedWithMe,
  myDrive,
  posImg
}

export const workspaceActivities = {
  newProductWorkspace,
  manageOrdersWorkspace,
  addOrdersWorkspace,
  workspaceBannerImg,
  connectWorkspace,
  realTimeData,
  yourCustomers,
  allChats,
  manageAdminInbox,
  otherTags,
  rewardProgramme,
  pointRedemption,
  orderLineCrm,
  openInsight,
  allDashboardsWorkspace,
  addDashboard,
  yourStoredFiles,
  uploadFilesWorkspace,
  createDocs,
  allPagesWorkspace,
  createNewPage,
  createNewBlog,
  workdayDashboard,
  workdayInbox,
  workdayDocument,
  workdayTeamwork,
  workdayTemplate,
  workdaySpaces,
  goToWorkspace,
  goToDashboard,
  otherSystemsWorkday,
  otherSystemsLinecrm,
  goToDrive,
  goToBuilder
}