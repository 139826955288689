import React, { useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "react-oauth2-code-pkce";
import { Icons } from "src/components/ui/icons";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Input } from "src/components/ui/input";
import { loginSchema } from "./validations/loginSchema";
import { useUser } from "src/hooks/useUser";

export default function Welcome() {
  const { t, i18n } = useTranslation();
  const { login } = useUser();
  const { login: lineLogin } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loginSection, setLoginSection] = useState(true)

  const formik = useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    validationSchema: loginSchema,
    onSubmit: values => {
      login("email", values).then((res) => {
        if (res?.token) {
          navigate("/dashboard/app", { replace: true });
        }
      })
    },
  })
  const lngs = {
    th: { nativeName: "TH" },
    en: { nativeName: "EN" }
  }

  return (
    <div className="flex min-h-screen relative bg-white">
      <div className="flex w-full justify-center">
        <div className={`w-120 flex flex-col items-center justify-center py-5 px-10 box-content`}>
          <Icons.zaviagoIconHome />
          <section className={`mt-10 flex flex-col gap-y-6 w-full items-center`}>
            <div className="flex flex-col gap-y-[38px] items-center">
              <h2 className="text-3xl text-[#0F0F10] font-bold leading-10 text-center">{loginSection ? t('Sign in to your account') : t("Create new account")}</h2>
              {
                process.env.REACT_APP_ENV !== "production" && (
                  <form className="flex flex-col gap-y-4 w-full" onSubmit={formik.handleSubmit}>
                    {
                      Object.keys(formik.errors).length > 0 && (
                        <div className="flex flex-col gap-y-1 bg-[#F7F7F7] w-full p-2 rounded-md">
                          <p className="text-red-500 text-sm">{formik.errors.email}</p>
                          <p className="text-red-500 text-sm">{formik.errors.password}</p>
                        </div>
                      )
                    }
                    <Input
                      name="username"
                      placeholder={t('username/email')}
                      value={formik.values.username}
                      onChange={formik.handleChange}
                    />
                    <Input
                      name="password"
                      type="password"
                      placeholder={t('password')}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                    <button type="submit" className="login-line-btn mx-auto">
                      {t('signin')}
                    </button>
                  </form>
                )
              }
              <button
                onClick={() => loginSection ? lineLogin(JSON.stringify(location.state)) : lineLogin()}
                className="login-line-btn"
              >
                {loginSection ? t('Login with') : t('Sign up with')}
                <Icons.lineIcon />
              </button>
            </div>

            <p className="text-base font-bold text-[#858585]">{t('or')}</p>

            <div className="flex gap-1 text-base">
              <span className="text-secondary-foreground">{t(loginSection ? 'dont_have_account' : 'already_have_account')}</span>
              <button onClick={() => setLoginSection(loginSection ? false : true)} className=" text-[#888888] underline decoration-1">
                {t(loginSection ? 'signup' : 'signin')}
              </button>
            </div>

            <div className="relative text-base flex flex-col gap-y-4 mt-16 items-center">
              <p className="text-darkblue">{t('need_help')} • <a href="https://page.line.me/zaviago">{t("contact_us")}</a></p>
              <div className="inline-flex gap-x-2">
                {Object.keys(lngs).map((lng) => (
                  <button
                    type="button"
                    key={lng}
                    onClick={() => { i18n.changeLanguage(lng); localStorage.setItem('lang', lng) }}
                    disabled={i18n.resolvedLanguage === lng}
                    className={`px-2 py-1 text-base leading-4 hover:bg-[#E3E3E3] cursor-pointer rounded-md ${i18n.resolvedLanguage === lng ? 'bg-[#E3E3E3]' : ''}`}
                  >
                    {lngs[lng].nativeName}
                  </button>
                ))}
              </div>
              <Link to="/privacy-policy" className="text-darkblue">Privacy Policy</Link>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}