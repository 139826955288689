import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from "src/components/ui/card"
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function FeatureCard({ 
  topIcon, 
  isCover, 
  title, 
  image, 
  icon, 
  desc, 
  link, 
  category, 
  featureName, 
  type, 
  titleColor = "", 
  background, 
  footerBgColor, 
  videoSrc, 
  hasFooter = true,
  loop,
  footerChildren
}){
  const { t } = useTranslation()
  const videoRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [shouldPlay, setShouldPlay] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 }
    );
    if (video) observer.observe(video);
    return () => { if (video) observer.unobserve(video); };
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const video = videoRef.current;
      if (document.visibilityState === "visible" && shouldPlay) {
        video.play().catch((error) => console.warn("Play error:", error));
      } else if (document.visibilityState === "hidden") {
        video.pause();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => { document.removeEventListener("visibilitychange", handleVisibilityChange); };
  }, [shouldPlay]);

  useEffect(() => {
    const video = videoRef.current;
    if (isVisible) {
      video.play().then(() => setShouldPlay(true)).catch((error) => {
        console.warn("Play error:", error);
        setShouldPlay(false);
      });
    } else {
      video.pause();
      setShouldPlay(false);
    }
  }, [isVisible]);

  return (
    <Card className={`overflow-hidden ${link ? 'cursor-pointer' : ''} ${hasFooter ? "h-full" : "h-[340px]"} ${type === "app" ? "text-white" : "shadow-featureCard"} border-0 rounded-2xl relative`} style={{background: background}} onClick={link}>
      {topIcon ? <span className="absolute top-6 left-6">{topIcon}</span> : null}
      <video 
        poster={image} 
        className={`absolute w-full h-full ${isCover ? " object-cover" : ""}`} 
        autoPlay muted loop={loop}
        ref={videoRef}
      >
        <source src={videoSrc} type="video/mp4" />
        No video
      </video>
      {hasFooter ? (
        <div className={type === "app" ? "app-card" : type === "video" ? "video-card" : "feature-card"}>
          {title || desc ? (
            <CardHeader className={`flex flex-col justify-end leading-5 px-6 py-4 ${type === "app" ? "h-[150px]" : "bg-[#FFFFFF75] backdrop-blur"}`}>
              <CardTitle className="text-xs font-normal !tracking-sfpro" style={{color: titleColor}}>{title}</CardTitle>
              <CardDescription className={`text-lg leading-7 ${type === "app" ? "text-white" : "text-darkergray-200 font-semibold"} !m-0`}>{desc}</CardDescription>
            </CardHeader>
          ) : null}
          <CardFooter className={`justify-between px-6 pt-5 pb-4 ${type === "app" ? "backdrop-blur-lg rounded-b-[16px]" : type === "video" ? "bg-transparent" : "bg-white"}`} style={{backgroundColor: footerBgColor}}>
            {type !== "video" ? (
              <>
                <div className="flex items-center gap-3">
                  <img src={icon} alt={title}/>
                  <div className="leading-5 space-y-1">
                    <h2 className={`text-lg tracking-sfpro ${type === "app" ? "" : " text-darkergray-200 font-semibold tracking-[0]"}`}>{featureName}</h2>
                    <p className={`text-sm tracking-sfpro ${type === "app" ? "text-[#FFFFFFC9]" : "text-lightgray-300"}`}>{category}</p>
                  </div>
                </div>
                <a className={`shadow-topbar tracking-sfpro bg-[#FFFFFF3D] leading-5 py-2 px-5 rounded-full text-base font-semibold ${type === "app" ? "" : "text-[#616161]"}`}>{t("Open")}</a>
              </>
            ) : footerChildren}
          </CardFooter>
        </div>
      ) : null}
    </Card>
  )
}