import { useTranslation } from "react-i18next";
import UpgradeProIcon from "../apps/upgradeProIcon"

const UpgradeLogo = ({ onClick }) => {
  const { t } = useTranslation();
  const gradientBtn = { background: "linear-gradient(139.97deg, #0075E0 -0.75%, #9E00E9 127.99%)" }
  return (
    <div style={gradientBtn} className='cursor-pointer inline-flex rounded-[10px] shadow-md text-white cal-sans h-[26px] text-xs font-semibold p-1.5 gap-x-[5px] leading-[15px]' onClick={onClick}>
      <UpgradeProIcon />
      Pro{/* {t('pro')} */}
    </div>
  )
}

export default UpgradeLogo