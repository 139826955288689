import { ChevronDownIcon } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar"
import { Command, CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator, CommandShortcut } from "../../components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "../../components/ui/popover"
import { DataList } from "src/components/pagination";
import { useUser } from "../../hooks/useUser";
import Loading from "src/components/ui/loading";
import { team } from "src/client/api";
import { useTranslation } from "react-i18next";
import { AccountModalButton, AccountModalMenus } from "src/components/topbar/components/AccountModalMenus";

export default function AllTeams() {
  const { auth } = useUser();
  const teamnames = auth?.teams
  const { t } = useTranslation()

  const TeamCard = (lteam) => {
    return (
      <AccountModalButton 
        className="p-4"
        icon={<Avatar>
          <AvatarImage />
          <AvatarFallback>{lteam.user[0].toUpperCase()}</AvatarFallback>
        </Avatar>}
        title={lteam.user}
        desc={auth?.team?.user === lteam.user ? "Owner" : "Member"}
      />
      // <div className="flex items-center justify-between space-x-4">
      //   <div className="flex items-center space-x-4">
      //     <Avatar>
      //       <AvatarImage />
      //       <AvatarFallback>{lteam.user[0].toUpperCase()}</AvatarFallback>
      //     </Avatar>
      //     <div>
      //       <p className="text-sm font-medium leading-none">{lteam.user}</p>
      //     </div>
      //   </div>
      //   <Popover>
      //     <PopoverTrigger asChild>
      //       <Button variant="secondary" className="ml-auto">
      //         {lteam.name === auth.team.name ? t('teams.current') : t('teams.switch')}
      //         <ChevronDownIcon className="ml-2 h-4 w-4 text-muted-foreground" />
      //       </Button>
      //     </PopoverTrigger>
      //     <PopoverContent className="p-0 w-[140px]" align="end">
      //       <Command>
      //         <CommandList>
      //           <CommandGroup className="p-1.5">
      //             {lteam.name !== auth.team.name && (
      //               <CommandItem onSelect={() => team.switch_team(lteam.name).then(() => window.location.reload())}>
      //                 {t('teams.switch')}
      //               </CommandItem>
      //             )}
      //             <CommandItem>{t('teams.view_members')}</CommandItem>
      //           </CommandGroup>
      //         </CommandList>
      //       </Command>
      //     </PopoverContent>
      //   </Popover>
      // </div>
    )
  }

  return (
    <div className="flex flex-col gap-10">
      {auth ? (
        <>
          <AccountModalMenus title="CURRENT TEAM">
            <TeamCard {...auth.team} />
          </AccountModalMenus>
          {/* <div>
            <h1 className="subheading font-medium my-6">{t('teams.current_team')}</h1>
            <div className="flex flex-col gap-y-6 mt-[10px]">
              <TeamCard {...auth.team} />
            </div>
          </div> */}

          <AccountModalMenus title="ALL YOUR TEAM" className="bg-white">
            <section className="flex flex-col gap-3">
              {teamnames.map(t => (
                <AccountModalMenus onClick={() => team.switch_team(t.name).then(() => window.location.reload())}>
                  <TeamCard key={t.name} {...t} />
                </AccountModalMenus>
              ))}
            </section>
          </AccountModalMenus>
          {/* <div>
            <h1 className="subheading font-medium my-6">{t('teams.teams')}</h1>
            <div className="flex flex-col gap-y-6 mt-[10px]">
              <DataList pagination={teamnames.length > 6 ? true : false} listPerPage={6}>
                {teamnames.map(t => <TeamCard key={t.name} {...t} />)}
              </DataList>
            </div>
          </div> */}
        </>
      ) : <Loading />}
    </div>
  )
}