import { ChevronLeft } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog, DialogContent } from "../ui/dialog";
import { cn } from "src/lib/utils";
import useModal from "src/hooks/useModal";
import useTopbarMenu from "src/hooks/useTopbarMenu";

export function MainModal({
  children,
  mainClassName,
  type,
  open,
  onClose
}) {
  const initialClassName = "p-12 pt-8 overflow-auto"
  return (
    <Dialog open={open} onOpenChange={onClose}>
      {type === "register" ? (
        <div className="flex items-center justify-center h-screen relative">
          <div className="bg-[#00000059] rounded-xl max-h-[700px] max-w-[672px] w-[90%] h-[90%] absolute -translate-y-3 z-99"></div>
        </div>
      ) : null}
      <DialogContent className={cn("main-modal", {"!animate-none": type === "register"}, {"!pt-0 lg:min-w-[895px] lg:min-h-[752px]": type === "workspace"})}>
        <main className={mainClassName ? cn(initialClassName, mainClassName) : initialClassName}>
          {children}
        </main>
      </DialogContent>
    </Dialog>
  )
}

export const MainModalHeader = ({
  title,
  rightButton,
  onClickBack,
  showLeftButton
}) => {

  const { closeModal, modalProps, isSaving } = useModal();
  const { checkIfHasForms } = useTopbarMenu()
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <header className="main-modal-header sf-pro">
      <button className={cn("flex items-center gap-1.5 text-link text-base !outline-none w-fit", { "invisible": !showLeftButton })} onClick={() => {
        if (onClickBack) onClickBack();
        location.key === "default" ? closeModal() : navigate(-1)
      }}>
        <ChevronLeft />
        Back
      </button>
      <h2 className="font-medium text-lg text-center whitespace-pre">{title}</h2>
      {rightButton ? (
        <div className="flex justify-end mr-3">
          <button className="text-link text-base !outline-none w-fit" onClick={checkIfHasForms ? modalProps.primaryAction : closeModal} disabled={isSaving}>
            {rightButton}
          </button>
        </div>
      ) : null}
    </header>
  )
}