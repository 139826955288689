import { workspaceImages } from "src/components/icon-menus/workspace-images"
import { Dialog, DialogTrigger, DialogContent, DialogOverlay, DialogPortal }  from"src/components/ui/dialog"
import workspaceApp from "src/img/workspace-banner-imgs/workspace-app.png"
import webstudioApp from "src/img/workspace-banner-imgs/webstudio-app.png"
import whiteboardApp from "src/img/workspace-banner-imgs/whiteboard-app.png"
import documentApp from "src/img/workspace-banner-imgs/document-app.png"
import presentationApp from "src/img/workspace-banner-imgs/presentation-app.png"
import workdayApp from "src/img/workspace-banner-imgs/workday-app.png"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const AppsListModal = ({ trigger }) => {
  const appsList = [
    { title:"whiteboard", desc:"The innovation Whiteboard where you work together", icon:workspaceImages.whiteboard, iconBg:"#FBBD43", bgApp:whiteboardApp },
    { title:"presentation", desc:"Turns presentations into your superpower", icon:workspaceImages.presentationIcon, iconBg:"#25671F", bgApp:presentationApp },
    { title:"workspace", desc:"ทุกอย่างในการจัดการและบริหารธุรกิจของคุณ", icon:workspaceImages.manageBusiness, iconBg:"#106CFF", bgApp:workspaceApp },
    { title:"webstudio", desc:"The web builder for stunning sites", icon:workspaceImages.manageWebsite, iconBg:"#F7235B", bgApp:webstudioApp },
    { title:"document", desc:"สร้างเอกสาร และ ข้อมูลต่างๆ", icon:workspaceImages.document, iconBg:"#007AFF", bgApp:documentApp },
    { title:"workday", desc:"ระบบแชทกับทีมงานและสร้าง todo", icon:workspaceImages.workday, iconBg:"#000000", bgApp:workdayApp }
  ]

  const textStyles = {
    textShadow:"0 2px 4px #00000040",
    color:"white"
  }

  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DialogTrigger>
        {trigger}
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay className="backdrop-blur-[46px]"/>
        <DialogContent showCloseButton={false} onClick={() => setIsModalOpen(false)} className="h-min !w-[90%] !max-w-[1200px] !bg-transparent !shadow-none !border-0 !py-12 !px-0 !outline-none rounded-none">
          <section className="grid grid-cols-3 gap-y-10 gap-x-20 relative z-[9] h-fit">
            {appsList.map(app => (
              <div className="flex flex-col gap-3 cursor-pointer" onClick={(e) => {
                e.stopPropagation();
                navigate(`/dashboard/workspace${app.title === "workspace" ? "" : `/${app.title}`}`)
              }}>
                <div className="flex items-center gap-2">
                  <div style={{backgroundColor:app.iconBg, boxShadow:"0 1px 4px 0 #00000040"}} className="p-2.5 rounded-xl">
                    <img src={app.icon} className="block w-6 h-6"/>
                  </div>
                  <div>
                    <h1 style={textStyles} className="text-lg font-semibold leading-7">{app.title}</h1>
                    <p style={textStyles} className="text-xs">{app.desc}</p>
                  </div>
                </div>

                <img src={app.bgApp} className="rounded-lg" style={{boxShadow:"0 4px 13.3px 0 #00000040"}}/>
              </div>
            ))}
          </section>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  )
}

export default AppsListModal