import React, { useState } from 'react';
import { Dialog, DialogContent } from "../ui/dialog";
import { Button } from '../ui/button';
import Loading from '../ui/loading';
import { Maximize2, XCircle } from 'lucide-react';
import { cn } from 'src/lib/utils';

export default function IframeModal({ iframeSrc, isOpen, onClose }) {
  const [isOpeningInWindow, setIsOpeningInWindow] = useState(false)
  const OpenWindow = (iframeSrc) => {
    setTimeout(() => window.open(iframeSrc, "_self"), 500)
  }
  const handleOpenWindow = () => {
    setIsOpeningInWindow(true)
    OpenWindow(iframeSrc)
  }
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className={cn('iframe-content', {'active-iframe': isOpeningInWindow})}>
        <section className='absolute flex gap-2 -top-[52px]'>
          <Button
            className="!bg-white text-black font-semibold !shadow-none flex items-center gap-2 h-11"
            onClick={() => iframeSrc ? handleOpenWindow() : setIsOpeningInWindow(false)}
          >
            <Maximize2 className="h-5 w-5" />
            Open in Window
          </Button>
          <Button 
            className="!bg-[#FFEBEB] border text-black font-semibold !border-[#FF98984F] !shadow-none flex items-center gap-2 h-11"
            onClick={onClose}
          >
            <XCircle className="h-5 w-5" />
            Close
          </Button>
        </section>
        <iframe src={iframeSrc} className={cn("rounded-[23px] w-full h-full z-[9] border-0", {"rounded-none duration-500": isOpeningInWindow})} allow="autoplay"/>
        <div className="flex justify-center items-center absolute z-[8] w-full h-full">
          <Loading />
        </div>
      </DialogContent>
    </Dialog>
  );
}
