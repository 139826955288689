import { Separator } from "src/components/ui/separator";
import { Input } from "src/components/ui/input";
import { Button } from "src/components/ui/button"
import { Calendar } from "src/components/ui/calendar"
import { cn } from "../../lib/utils"
import { Plus, Wallet, X } from "lucide-react";
import { Card } from "src/components/ui/card";
import { UploadCloud } from "lucide-react";
import { ArrowLeft } from "lucide-react";
import { useState,useRef  } from 'react'
import { format } from "date-fns"
import krungthai from 'src/img/krungthai.svg'
import krungthepBank from 'src/img/krungthep-bank.svg'
import kasikorn from 'src/img/kasikorn.svg'
import krungsri from 'src/img/krungsri.svg'
import scb from 'src/img/scb.svg'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "src/components/ui/dialog"
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { site } from "../../client/api";
import { useMutation } from "react-query";


export default function CheckoutConfirm({invoiceId,InvoiceDate,paymentConfirm, setPaymentConfirm, totalPrice}){
  const { t } = useTranslation()
  const { id } = useParams()
  const { app } = useParams()
  const [date, setDate] = useState()
  const hour = new Date().getHours();
  const minute = new Date().getMinutes();
  const fileInputRef = useRef(null);

  const banks = [
    { icon:krungthai, text:'Krung Thai Bank'},
    { icon:krungthepBank, text:'Bangkok Bank'},
    { icon:kasikorn, text:'Kasikorn Bank'},
    { icon:krungsri, text:'Bank of Ayudhaya'},
    { icon:scb, text:'Siam Commercial Bank'}
  ]

  const [imagePreview, setImagePreview] = useState(null);
  const [imageName, setImageName] = useState('')
  const [otherBank, setOtherBank] = useState(false)

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    
    // Check if the file is of type JPG or PNG
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setImageName(file.name);
      };
      reader.readAsDataURL(file);
    } else {
      // Handle invalid file type
      alert("Please upload a valid image file (PNG or JPG).");
      event.target.value = ''; // Clear the input
    }
  };

  const handleRemoveImage = (e) => {
    e.preventDefault();
    setImagePreview(null)
  }

  const displayOtherBank = (val) => {
    if (val === 'Other'){
      setOtherBank(true)
    } else {
      setOtherBank(false)
    }
  }

  const ShowPreview = () => {
    return (
      <Dialog>
        <DialogTrigger>
          <div className="min-w-20 min-h-20 w-20 h-20">
            <img src={imagePreview} className="w-full h-full object-cover" />
          </div>
        </DialogTrigger>
        <DialogContent>
          <img src={imagePreview} className="w-full"/>
        </DialogContent>
      </Dialog>
    )
  }

  const base64ToBlob = (base64, mimeType) => {
    const byteString = atob(base64.split(',')[1]); // Remove the base64 prefix and decode
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
  
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
  
    return new Blob([uint8Array], { type: mimeType });
  };


  const { mutateAsync } = useMutation(site.insert_invoice_receipt, {
    onSuccess: () => {
        window.location.href=`/checkout-received/${app}/${id}`
    },
    onError: (error) => {
        console.error('Error during file upload:', error);
    },
});

const handleSubmit = async () => {
    if (imagePreview) {
        try {
            const fileData = imagePreview.split(',')[1]; // This removes the data URL prefix
            await mutateAsync({
                file: fileData, // Send the base64 string
                invoice_id: invoiceId // Send the invoice ID
            });
        } catch (error) {
            console.error('Error uploading the file:', error);
        }
    }
};


  return (
    <section className="p-4 md:pt-[60px]">
      <div className={`flex items-center gap-x-3 mb-6 ${paymentConfirm ? '' : 'px-10'}`}>
        <button onClick={() => setPaymentConfirm(false)}>
          <ArrowLeft />
        </button>
        <h1 className="primary-heading">{t('payment.title')}</h1>
      </div>
      <h2 className="secondary-heading">{t('payment.verify_payment')}</h2>
      <p className="main-desc">{t('payment.verify_payment_desc')}</p>

      <Separator className='my-6'/>

      <main className="flex flex-col gap-y-6">
        <table className="w-full table-invoice-detail">
          <tbody>
            <tr className="main-desc my-4">
              <td className="text-[#424242">{t("payment.payment_notifications.invoice_number")}</td>
              <td className="text-right">{invoiceId}</td>
            </tr>
            <tr className="main-desc">
              <td className="text-[#424242]">{t("payment.payment_notifications.date")}</td>
              <td className="text-right">
                {new Date(InvoiceDate).toLocaleDateString('en-CA')} {/* Formats date as YYYY-MM-DD */}
              </td>
            </tr>
            <tr className="main-desc">
              <td className="text-[#424242]">{t("payment.payment_notifications.status")}</td>
              <td className="text-right">{t("payment.payment_notifications.in_progress")}</td>
            </tr>
            <tr className="main-desc">
              <td className="text-[#424242]">{t("payment.payment_notifications.amount")}</td>
              <td className="text-right">{totalPrice}</td>
            </tr>
          </tbody>
        </table>

        <div className="space-y-6">
          <div className="anim-up flex flex-col">
            <label htmlFor='upload-slip'>
              {imagePreview ? (
                <Card className='rounded-md p-4 flex items-center flex-row gap-x-3 relative'>
                  <ShowPreview />
                  <p className="subheading font-medium">{imageName}</p>

                  <button className="absolute right-4 top-4" onClick={handleRemoveImage}>
                    <X className="h-4 w-4"/>
                  </button>
                </Card>
              ) : (
                <>
                  <Input
                    ref={fileInputRef}
                    className="hidden"
                    name="upload-slip"
                    type='file'
                    id='upload-slip'
                    // onChange={form.handleChange}
                    // defaultValue={billingAddress.billing_name}
                    onChange={handleImageChange}
                  />
                  <Card className='rounded-md p-8 flex items-center flex-col gap-y-3 cursor-pointer'>
                    <div className="h-10 w-10 flex items-center justify-center bg-accent rounded-full">
                      <UploadCloud viewBox="0 0 24 24" width='20' height='20'/>
                    </div>
                    <div className="text-center">
                      <h2 className="subheading font-medium">{t('upload_slip')}</h2>
                      <p className="main-desc">{t('upload_slip_limit')}</p>
                    </div>
                  </Card>
                </>
              )}
            </label>
          </div>
        </div>
        <Button type="submit" className="btn-with-icon w-full" onClick={handleSubmit}>
          <Wallet color="#FFF" viewBox="0 0 24 24" height="16" width="16" />
          {t("confirm")}
        </Button>
      </main>
    </section>
  )
}