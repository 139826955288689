import { CheckCircle2, ClipboardList, FolderTree, Package, PackageOpen, Settings, Ticket, Truck, Users, Warehouse, Workflow, Undo2, Crown, Gift, Carrot, Star, Gem, FolderGit2, GitBranchPlus, FileDigit, History, ExternalLink, Trash2 } from "lucide-react"
import { workspaceActivities, workspaceImages } from "../icon-menus/workspace-images"

const iconClassName = "stroke-1 w-7 h-7"

export const workspaceTriggers = [
  { title: "สำหรับคุณ", type: "workspace", icon: workspaceImages.mainIcon },
  { title: "จัดการธุรกิจ", type: "inbox", icon: workspaceImages.manageBusiness },
  { title: "ข้อมูลลูกค้า", type: "workday", icon: workspaceImages.customerDataSystem },
  { title: "จัดการเว็บไซต์", type: "builder", icon: workspaceImages.manageWebsite },
  { title: "Projects", type: "data-insight", icon: workspaceImages.projectManagement },
  { title: "Files", type: "drive", icon: workspaceImages.graphicDesign },
  { title: "รวมแชท", type: "line-crm", icon: workspaceImages.pos }
]

export const workspaceList = [
  {
    title: "รายการสินค้าของคุณ",
    desc: "จัดรายการไอเทมสินค้าต่างๆของคุณ",
    link: "sales-invoice",
    imageUrl: workspaceActivities.newProductWorkspace,
    type: "workspace"
  },
  {
    title: "จัดการคำสั่งซื้อ",
    desc: "จัดรายการไอเทมสินค้าต่างๆของคุณ",
    imageUrl: workspaceActivities.manageOrdersWorkspace,
    type: "workspace",
    imagePadding: "0 28px 0 0"
  },
  {
    title: "เชื่อมต่อช่องทาง",
    desc: "Connect with all customers",
    imageUrl: workspaceActivities.connectWorkspace,
    type: "inbox"
  },
  {
    title: "Real time data",
    desc: "See how many conversations are currently open",
    imageUrl: workspaceActivities.realTimeData,
    type: "inbox"
  },
  // {
  //   title: "ลูกค้าของคุณ",
  //   desc: "Create a blank presentation",
  //   imageUrl: workspaceActivities.yourCustomers,
  //   type: "inbox"
  // },
  {
    title: "เวิร์คเดย์ แดชบอร์ด",
    desc: "การทำงานทั้งหมดของคุณ",
    imageUrl: workspaceActivities.workdayDashboard,
    type: "workday"
  },
  {
    title: "อินบ็อกซ์",
    desc: "See how many conversations are currently open",
    imageUrl: workspaceActivities.workdayInbox,
    type: "workday"
  },
  // {
  //   title: "เอกสาร",
  //   desc: "Create a blank presentation",
  //   imageUrl: workspaceActivities.workdayDocument,
  //   type: "workday"
  // },
  {
    title: "รีวอร์ดโปรแกรม",
    desc: "สร้างจัดการรีวอร์ดของคุณ",
    imageUrl: workspaceActivities.rewardProgramme,
    type: "line-crm"
  },
  {
    title: "การแลกแต้ม",
    desc: "ข้อมูลการแลกแต้มของลูกค้า",
    imageUrl: workspaceActivities.pointRedemption,
    type: "line-crm"
  },
  // {
  //   title: "เพิ่มคำสั่งซื้อ",
  //   desc: "ข้อมูลการแลกแต้มของลูกค้า",
  //   imageUrl: workspaceActivities.orderLineCrm,
  //   type: "line-crm"
  // },
  {
    title: "Open Insight",
    desc: "เปิดใช้งาน",
    imageUrl: workspaceActivities.openInsight,
    type: "data-insight",
    imagePadding: "16px 0 0"
  },
  {
    title: "แดชบอร์ดทั้งหมด",
    desc: "ดูแดชบอร์ดของคุณ",
    imageUrl: workspaceActivities.allDashboardsWorkspace,
    type: "data-insight"
  },
  // {
  //   title: "สร้างแดชบอร์ด",
  //   desc: "สร้างใหม่",
  //   imageUrl: workspaceActivities.addDashboard,
  //   type: "data-insight"
  // },
  {
    title: "คลังไฟล์ของคุณ",
    desc: "ไฟล์ทั้งหมด",
    imageUrl: workspaceActivities.yourStoredFiles,
    type: "drive",
    imagePadding: "16px 0 0"
  },
  {
    title: "Upload file",
    desc: "Create a blank frame",
    imageUrl: workspaceActivities.uploadFilesWorkspace,
    type: "drive"
  },
  // {
  //   title: "สร้าง Docs",
  //   desc: <>Create new Docs <br/>in your drive</>,
  //   imageUrl: workspaceActivities.createDocs,
  //   type: "drive"
  // },
  {
    title: "เพจทั้งหมด",
    desc: "เว็บเพจของคุณ",
    imageUrl: workspaceActivities.allPagesWorkspace,
    type: "builder"
  },
  {
    title: "สร้างเพจใหม่",
    desc: "Create new page",
    imageUrl: workspaceActivities.createNewPage,
    type: "builder"
  },
  // {
  //   title: "Blog",
  //   desc: "Create new blogs",
  //   imageUrl: workspaceActivities.createNewBlog,
  //   type: "builder"
  // }
]

export const anotherWorkspaceList = [
  {
    title: "ดูแชททั้งหมด",
    desc: "จัดการอินบ็อกซ์ทั้งหมด",
    imageUrl: workspaceActivities.allChats,
    type: "inbox"
  },
  {
    title: "จัดการแอดมิน",
    desc: "เพิ่มทีมงานตอบแชท",
    imageUrl: workspaceActivities.manageAdminInbox,
    type: "inbox"
  },
  {
    title: "แท็กต่างๆ",
    desc: "จัดการข้อมูลแท็กของระบบ",
    imageUrl: workspaceActivities.otherTags,
    type: "inbox"
  },
  {
    title: "ทีมงาน",
    desc: "จัดการอินบ็อกซ์ทั้งหมด",
    imageUrl: workspaceActivities.workdayTeamwork,
    type: "workday"
  },
  {
    title: "เทมเพลต",
    desc: "เพิ่มทีมงานตอบแชท",
    imageUrl: workspaceActivities.workdayTemplate,
    type: "workday"
  },
  {
    title: "Spaces",
    desc: "จัดการข้อมูลแท็กของระบบ",
    imageUrl: workspaceActivities.workdaySpaces,
    type: "workday"
  },
]

export const workspaceAdvantages = [
  {
    title: "สินค้าของฉัน",
    link: "item",
    desc: "จัดการสินค้าของคุณในระบบ",
    section: "workspace",
    icon: <Package className={iconClassName}/>
  },
  {
    title: "จัดการคำสั่งซื้อ",
    link: "sales-invoice",
    desc: "ติดตามสถานะและจัดการคำสั่งซื้อของคุณ",
    section: "workspace",
    icon: <ClipboardList className={iconClassName}/>
  },
  {
    title: "การแจ้งโอนเงิน",
    link: "payment-entry",
    desc: "ตรวจสอบธุรกรรมการโอนเงินจากลูกค้า",
    section: "workspace",
    icon: <CheckCircle2 className={iconClassName}/>
  },
  {
    title: "ลูกค้า",
    link: "customer",
    desc: "ดู, เพิ่ม, และอัปเดตข้อมูลลูกค้าของคุณ",
    section: "workspace",
    icon: <Users className={iconClassName}/>
  },
  {
    title: "หมวดหมู่สินค้า",
    link: "item-group/view/tree",
    desc: "จัดการหมวดหมู่สินค้าของคุณ",
    section: "workspace",
    icon: <FolderTree className={iconClassName}/>
  },
  {
    title: "คลังสินค้า",
    link: "warehouse/view/tree",
    desc: "ดูและควบคุมสินค้าคงคลังของคุณ",
    section: "workspace",
    icon: <Warehouse className={iconClassName}/>
  },
  {
    title: "โค้ดส่วนลด",
    link: "coupon-code",
    desc: "สร้างและจัดการโค้ดส่วนลด",
    section: "workspace",
    icon: <Ticket className={iconClassName}/>
  },
  {
    title: "ตั้งค่าข้อมูลธุรกิจ",
    link: "company/Zaviago",
    desc: "ปรับแต่งข้อมูลธุรกิจของคุณ",
    section: "workspace",
    icon: <Settings className={iconClassName}/>
  },
  {
    title: "รายการแอตทริบิวต์",
    link: "item-attribute",
    desc: "จัดการรายการ สี ขนาด สไตล์ของสินค้า",
    section: "workspace",
    icon: <PackageOpen className={iconClassName}/>
  },
  {
    title: "หมวดหมู่ยี่ห้อสินค้า",
    link: "brand",
    desc: "หากคุณมีหลายแบรนด์ในร้านของคุณ",
    section: "workspace",
    icon: <Workflow className={iconClassName}/>
  },
  {
    title: "การจัดส่งสินค้า",
    desc: "ลิสคำสั่งซื้อที่ต้องจัดส่ง",
    section: "workspace",
    icon: <Truck className={iconClassName}/>
  },
  {
    title: "การคืนสินค้า",
    desc: "คำสั่งซื้อที่มีการขอคืน",
    section: "workspace",
    icon: <Undo2 className={iconClassName}/>
  },
  {
    title: "Mentions",
    desc: "จัดการสินค้าของคุณในระบบ",
    section: "inbox",
    icon: <Package className={iconClassName}/>
  },
  {
    title: "เงื่อนไขอัตโนมัติ",
    desc: "เพิ่มป้ายกำกับและมอบหมายงานให้พนักงานโดยอัตโนมัติ",
    section: "inbox",
    icon: <ClipboardList className={iconClassName}/>
  },
  {
    title: "คำตอบสำเร็จรูป",
    desc: "Canned Responses are pre-written reply templates that help you quickly respond to a conversation.",
    section: "inbox",
    icon: <CheckCircle2 className={iconClassName}/>
  },
  {
    title: "ทีมแอดมิน",
    desc: "จัดกลุ่มให้แอดมินตอบแชทหนึ่งคนสามารถ เข้าร่วมได้หลายทีม",
    section: "inbox",
    icon: <Users className={iconClassName}/>
  },
  {
    title: "Loyalty Program",
    link: "loyalty-program",
    desc: "จัดการสินค้าของคุณในระบบ",
    section: "line-crm",
    icon: <Crown className={iconClassName}/>
  },
  {
    title: "ของรางวัล",
    link: "loyalty-point-entry/view/report",
    desc: "ติดตามสถานะและจัดการคำสั่งซื้อของคุณ",
    section: "line-crm",
    icon: <Gift className={iconClassName}/>
  },
  {
    title: "ระดับลูกค้า",
    desc: "ตรวจสอบธุรกรรมการโอนเงินจากลูกค้า",
    section: "line-crm",
    icon: <Carrot className={iconClassName}/>
  },
  {
    title: "การแลกซื้อผ่านแต้ม",
    desc: "ดู, เพิ่ม, และอัปเดตข้อมูลลูกค้าของคุณ",
    section: "line-crm",
    icon: <Star className={iconClassName}/>
  },
  {
    title: "การแจกแต้ม",
    desc: "จัดการหมวดหมู่สินค้าของคุณ",
    section: "line-crm",
    icon: <Gem className={iconClassName}/>
  },
  {
    title: "ตั้งค่าพอยท์",
    desc: "ปรับแต่งข้อมูลธุรกิจของคุณ",
    section: "line-crm",
    icon: <Settings className={iconClassName}/>
  },
  {
    title: "Data Source",
    desc: "Manage your Data Source",
    section: "data-insight",
    icon: <FolderGit2 className={iconClassName}/>
  },
  {
    title: "Queries",
    desc: "Create or Manage Data Queries",
    section: "data-insight",
    icon: <GitBranchPlus className={iconClassName}/>
  },
  {
    title: "Data Notebooks",
    desc: "All your Data Queries Note book",
    section: "data-insight",
    icon: <FileDigit className={iconClassName}/>
  },
  {
    title: "Recents",
    desc: "All recent files uploaded or created",
    section: "drive",
    icon: <History className={iconClassName}/>
  },
  {
    title: "Favourites",
    desc: "ติดตามสถานะและจัดการคำสั่งซื้อของคุณ",
    section: "drive",
    icon: <Star className={iconClassName}/>
  },
  {
    title: "Shared",
    desc: "ตรวจสอบธุรกรรมการโอนเงินจากลูกค้า",
    section: "drive",
    icon: <ExternalLink className={iconClassName}/>
  },
  {
    title: "Trash",
    desc: "ดู, เพิ่ม, และอัปเดตข้อมูลลูกค้าของคุณ",
    section: "drive",
    icon: <Trash2 className={iconClassName}/>
  },
]