import { CheckCircle } from "@untitled-ui/icons-react/build/cjs"

const RadioSection = ({ title, list, onChange, type, name }) => {
  return (
    <fieldset className={title ? "space-y-5" : ""}>
        {title ? <legend className='secondary-heading text-darkergray-700'>{title}</legend> : null}
        <ul className="gap-4">
          {list.map(la => {
            return (
              <li key={la}>
                <label className="radio-button-check">
                  <span className="title">{la}</span>
                  <span>
                    {/* <svg
                      ariaHidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none">
                      <path
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </svg> */}
                    <CheckCircle />
                  </span>
                  <input name={name} className="sr-only" type={type} onChange={() => onChange(la)}/>
                </label>
              </li>
            )
          })}
        </ul>
      </fieldset>
  )
}

export default RadioSection
