import { useEffect, useState } from "react";
import { user } from "src/client/api";

const usePurchaseHistory = () => {
    const [userInvoices, setuserInvoices] = useState(null);
    const fetchData = async () => {
      if (userInvoices == null) {
        setuserInvoices(await user.getInvoices())
      }
    }
    useEffect(() => {
        fetchData()
    }, [])
    return userInvoices
}

export default usePurchaseHistory