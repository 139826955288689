import React, { useEffect } from "react";
import { format } from "date-fns"
import { useToast } from "./ui/use-toast"
import * as yup from "yup"
import { useFormik } from 'formik';
import { Toaster } from "./ui/toaster";
import { useTranslation } from "react-i18next";
import { AccountModalMenus } from "./topbar/components/AccountModalMenus";
import { Table, TableBody } from "./ui/table";
import InputSection from "./InputSection";
import Loading from "./ui/loading";
import useModal from "src/hooks/useModal";
import { user } from "src/client/api"

export function EditProfileForm({ preloadedValues }) {
  const { t } = useTranslation();
  const { setModalProps, setIsSaving } = useModal()

  const { toast } = useToast();

  const accountFormSchema = yup.object().shape({
    first_name: yup.string().required('First Name is a required field'),
    last_name: yup.string().required('Last name is a required field'),
    email: yup.string().email().required('Email is a required field'),
    mobile_no: yup.string().required('Phone is a required field'),
    // dob: yup.date().required('DOB is a required field'),
  })
  const onSubmitFunction = async (data) => {
    const isValid = await accountFormSchema.isValid(data)
    setIsSaving(true);
    if(isValid){
      user.updateUser(data).then()
      .then((response) => {
        if( response.status===200 && response.statusText==="OK" ){
          toast({
            title: "Profile updated",
            description: "Profile is updated successfully",
          })
          setIsSaving(false)
        }else{
          toast({
            title: "Something went wrong",
            description: "Please refresh the page or contact the support.",
          })
          setIsSaving(false)
        }
      })
    }
    return
  };
  const form = useFormik({
    initialValues: {
      first_name:"",
      last_name:"",
      email:"",
      // username:preloadedValues?.username || "",
      // dob:preloadedValues?.birth_date || "",
      mobile_no:""
    },
    validateOnChange: true,
    validationSchema: accountFormSchema,
    onSubmit: values => {
      console.log(values)
      onSubmitFunction(values)
    },
  })

  useEffect(() => {
    setModalProps({
      primaryAction: (e) => form.handleSubmit(e)
    })

    if (preloadedValues){
      form.setValues({
        first_name:preloadedValues?.first_name || "",
        last_name:preloadedValues?.last_name || "",
        email:preloadedValues?.email || "",
        mobile_no:preloadedValues?.mobile_no || ""
      });
    }
  }, [preloadedValues])

  const profileInputs = [
    { type:"text", placeholder:t("first_name"), label:t("first_name"), name:"first_name", defaultValue:preloadedValues?.first_name },
    { type:"text", placeholder:t("last_name"), label:t("last_name"), name:"last_name", defaultValue:preloadedValues?.last_name },
    { type:"email", placeholder:t("email"), label:t("email"), name:"email", defaultValue:preloadedValues?.email, value:preloadedValues?.email },
    { type:"tel", placeholder:t("phone"), label:t("phone"), name:"mobile_no", defaultValue:preloadedValues?.mobile_no },
  ]

  return (
    <>
      {preloadedValues ? <form onSubmit={form.handleSubmit}>
        <AccountModalMenus title="ACCOUNT" desc="Update / Edit your personal information" className="pl-4">
          <Table>
            <TableBody>
              {profileInputs.map(inp => (
                <InputSection 
                  type={inp.type} 
                  placeholder={inp.placeholder} 
                  label={inp.label} 
                  name={inp.name} 
                  defaultValue={inp.defaultValue}
                  value={inp.value || null}
                  onChange={form.handleChange}
                />
              ))}
            </TableBody>
          </Table>
        </AccountModalMenus>
      </form> : <Loading />}

      <Toaster />
    </>
  )
}