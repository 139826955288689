import { useFormik } from 'formik';
import React, { useContext } from 'react'
import { userInfoSchema } from './validations/otherInfoSchema';
import { useTranslation } from 'react-i18next';
import { MainModal, MainModalHeader } from 'src/components/modals/MainModal';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import { Button } from 'src/components/ui/button';
import { useMutation } from 'react-query';
import { partial } from '../../client/api';
import { setToken } from 'src/utils/helper';
import useSignup from 'src/hooks/useSignup';

const PersonalInfo = () => {

  const { t } = useTranslation();
  const { key } = useParams();
  const { idTokenData } = useContext(AuthContext);
  const { state } = useSignup();
  const navigate = useNavigate();

  const { mutate: registernow, isLoading } = useMutation((data) => partial.setupOauthAccount(data), {
    onSuccess: (res) => {
      setToken(res.token);
      if ("inviteCode" in state) {
        navigate(`/invite/${state.inviteCode}`);
      } else {
        navigate('/dashboard/instance-configuration');
      }
    },
    // onError: (err) => {
    //   setErrors(err.response.data._server_messages);
    // }
  });

  const formik = useFormik({
    initialValues: {
      first_name: idTokenData?.name.split(' ')[0] || '',
      last_name: idTokenData?.name.split(' ')[1] || '',
      email: idTokenData?.email || ''
    },
    validateOnChange: false,
    validationSchema: userInfoSchema,
    onSubmit: (data) => {
      // Move the logic from the original onSubmit in PersonalInfoForm here
      registernow({
        ...data,
        key,
      });
    },
  });

  return (
    <MainModal open={true} type="register">
      <MainModalHeader title="Register" />
      <form className="max-w-3xl w-10/12 m-auto" onSubmit={formik.handleSubmit}>
        <h1 className='text-2xl text-darkergray-700 font-bold'>Tell us about yourself?</h1>
        <div className='bg-lightgray-100 rounded-lg'>
          <div className="grid grid-cols-2 py-3 px-5 mt-6">
            <label>{t('first_name')}</label>
            <input
              placeholder={t('first_name')}
              name="first_name"
              onChange={formik.handleChange}
              value={formik.values.first_name}
              className="outline-none bg-transparent"
            />
            {
              formik.errors.first_name && (
                <p className="error">{formik.errors.first_name}</p>
              )
            }
          </div>
          <div className="grid grid-cols-2 py-3 px-5 border-t border-b border-lightgray-200">
            <label>{t('last_name')}</label>
            <input
              placeholder={t('last_name')}
              name="last_name"
              onChange={formik.handleChange}
              value={formik.values.last_name}
              className="outline-none bg-transparent"
            />
            {
              formik.errors.last_name && (
                <p className="error">{formik.errors.last_name}</p>
              )
            }
          </div>
          <div className="grid grid-cols-2 py-3 px-5">
            <label >{t('email')}</label>
            <input
              placeholder={t('email')}
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              className="outline-none bg-transparent"
            />
            {
              formik.errors.email && (
                <p className="error">{formik.errors.email}</p>
              )
            }
          </div>
        </div>

        <div className="anim-up-delay mt-20 flex justify-center">
          <Button
            type='submit'
            className="regis-btn"
          // disabled={!formik.isValid}
          >Next step</Button>
        </div>
      </form>
    </MainModal>
  )
}

export default PersonalInfo